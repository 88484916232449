import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var $:any;
declare var swal:any;
declare var require: any
@Component({
  selector: 'app-ip-configuration',
  templateUrl: './ip-configuration.component.html',
  styleUrls: ['./ip-configuration.component.css']
})
export class IpConfigurationComponent implements OnInit {

    lan_options = [{'value':'automatic', 'id':'lan_option1', 'name' : 'Obtain an IP address automatically'},{'value':'manual', 'id':'lan_option2', 'name' : 'Use the following IP address'}];
    wan_options = [{'value':'automatic', 'id':'wan_option1', 'name' : 'Obtain an IP address automatically'},{'value':'manual', 'id':'wan_option2', 'name' : 'Use the following IP address'}];

    ipConfigurationData:FormGroup;
    aioNetworkDatas:FormGroup;
    wanOptionData:string;
    lanOptionData:string;
    mcAddress;
    wanMACAddress;
    lanMACAddress;
    lanstatus;
    wanstatus;
    openNetDet = false;
    showConsole = false;
    showLaunchPad = false;
    aioLantype;
    aioLanmac;
    aioLanaddress;
    aioLannetmask;
    aioLangateway;
    
    private ip_lan_address: string[] = [null, null, null,null];
    private ip_lan_netmask: string[] = [null, null, null,null];
    private ip_lan_gateway: string[] = [null, null, null,null];
    private ip_wan_address: string[] = [null, null, null,null];
    private ip_wan_netmask: string[] = [null, null, null,null];
    private ip_wan_gateway: string[] = [null, null, null,null];
    private ip_dns1: string[] = [null, null, null,null];
    private ip_dns2: string[] = [null, null, null,null];
    private aioLan_Address;
    private aioLan_Netmask: string[] = [null, null, null,null];
    private aioLan_Gateway: string[] = [null, null, null,null];
    hostname:string;
    main_data = this;
    
  constructor(private serverService: ServerService, private router:Router) { 
  
  }

  ngOnInit() {

        this.ipConfigurationData = new FormGroup({
            'ip_lan_address' : new FormArray([]), 
            'ip_lan_netmask' : new FormArray([]), 
            'ip_lan_gateway' : new FormArray([]), 
            'ip_wan_address' :  new FormArray([]), 
            'ip_wan_netmask' : new FormArray([]), 
            'ip_wan_gateway' : new FormArray([]), 
            'ip_dns1' : new FormArray([]),
            'ip_dns2' : new FormArray([]),
            'lan_ip' : new FormControl(),
            'wan_ip' : new FormControl(),
        });

      this.hostname = $(location).attr('hostname');
       this.getIpConfigData();
       this.showNetworkDetails();

       this.aioNetworkDatas = new FormGroup({
        'aioLanmac': new FormControl(),
        'aioLan_Address' : new FormArray([]), 
        'aioLan_Netmask' : new FormArray([]), 
        'aioLan_Gateway' : new FormArray([]), 
      });

  }
    
    

    
    lanOptions(lan_option){
        var ip_data;
        if (lan_option == "manual") {

            $(".ip_lan").each(function () {

                ip_data = $(this);
                ip_data.addClass('ip_data');
                ip_data.removeClass('ip_hide');
                ip_data.attr('readonly', false);

            });

        } else {

            $(".ip_lan").each(function () {
                ip_data = $(this);
                ip_data.removeClass('ip_data');
                ip_data.addClass('ip_hide');
                ip_data.attr('readonly', true);

            });

        }
        
    }
    
    
    wanOptions(wan_option){
         var ip_data;
        if (wan_option == "manual") {

            $(".ip_wan").each(function () {
                ip_data = $(this);
                ip_data.addClass('ip_data');
                ip_data.removeClass('ip_hide');
                ip_data.attr('readonly', false);

            });

        } else {

            $(".ip_wan").each(function () {
                ip_data = $(this);
                ip_data.removeClass('ip_data');
                ip_data.addClass('ip_hide');
                ip_data.attr('readonly', true);

            });

        }
        
    }
    
    gatewayOptions(gatewayOptions){
        var ip_data;
        $(".Gateway_LAN").each(function () {
            ip_data = $(this);
            ip_data.addClass('ip_data');
            ip_data.removeClass('ip_hide');
            ip_data.attr('readonly', false);

        });
          
    }
    
    checkLanOptions(lan_option){
        
        if(lan_option == "manual"){
             var readonly_data = true;
        }
        else{
             var readonly_data = false;
        }
        
        return readonly_data;
        
    }
    
    checkWanOptions(wan_option){
        
        if(wan_option == "manual"){
             var readonly_data = true;
        }
        else{
             var readonly_data = false;
        }
        
        return readonly_data;
        
    }
    
    
    ipConfigValidation() {

        var ipConfig = this.ipConfigurationData.value;
        console.log(ipConfig);

        if (ipConfig.lan_ip == null) {

            swal("Error", "Please choose any one LAN options", "error");
            return false;

        }

        if (ipConfig.wan_ip == null) {

            swal("Error", "Please choose any one WAN options", "error");
            return false;

        }

        // if (ipConfig.Gateway_LAN == null){
        //     swal("Error", "Please choose any one GatewayLAN options", "error");
        //     return false;

        // }
        
        this.lanOptions(ipConfig.lan_ip);
        this.wanOptions(ipConfig.wan_ip);
        //this.gatewayOptions(ipConfig.Gateway_LAN);

        var dns1 = true;

        $(".dns1").each(function () {
            var ip_data = $(this);
            if (ip_data.val() != "") {
                dns1 = false;
            }

            ip_data.removeClass('ip_data');

        });

        var dns2 = true;

        $(".dns2").each(function () {
            var ip_data = $(this);
            if (ip_data.val() != "") {
                dns2 = false;
            }

            ip_data.removeClass('ip_data');

        });

        if (dns1!=true) {

            $(".dns1").each(function () {
                var ip_data = $(this);
                ip_data.addClass('ip_data');

            });

        }
        if (dns2!=true) {

//            $(".dns2").each(function () {
//                var ip_data = $(this);
//               ip_data.addClass('ip_data');
//
//            });

        }
        var ip_data_status = true;
        $(".ip_data").each(function () {
            var ip_data = $(this);
            if (ip_data.val() == "") {
                $(ip_data).addClass('invalid_ip_data');
                ip_data_status = false;
            }
        });
        return ip_data_status;

    }
    
    UpdateSystemIP(command_type){
        
            var validation_data = this.ipConfigValidation();
            if (validation_data != true) {
                return false;
            }
            var ip_dns1, ip_dns2, ip_lan_address, ip_lan_gateway, ip_lan_netmask, ip_wan_address, ip_wan_gateway, ip_wan_netmask, lan_ip, wan_ip,ip_config_req;

            var ipConfig = this.ipConfigurationData.value;
            lan_ip = ipConfig.lan_ip;
            wan_ip = ipConfig.wan_ip;
            ip_lan_address = "";
            if (ipConfig.ip_lan_address[0] != null) {
                ip_lan_address = ipConfig.ip_lan_address[0] + '.' + ipConfig.ip_lan_address[1] + '.' + ipConfig.ip_lan_address[2] + '.' + ipConfig.ip_lan_address[3];
            }

            ip_lan_gateway = "";
            if (ipConfig.ip_lan_gateway[0] != null) {
                ip_lan_gateway = ipConfig.ip_lan_gateway[0] + '.' + ipConfig.ip_lan_gateway[1] + '.' + ipConfig.ip_lan_gateway[2] + '.' + ipConfig.ip_lan_gateway[3];
            }
            ip_lan_netmask = "";
            if (ipConfig.ip_lan_netmask[0] != null) {
                ip_lan_netmask = ipConfig.ip_lan_netmask[0] + '.' +ipConfig.ip_lan_netmask[1] + '.' + ipConfig.ip_lan_netmask[2]+ '.' + ipConfig.ip_lan_netmask[3];

            }

            ip_wan_address = "";
            if (ipConfig.ip_wan_address[0] != null) {
                ip_wan_address = ipConfig.ip_wan_address[0]+ '.' + ipConfig.ip_wan_address[1] + '.' + ipConfig.ip_wan_address[2] + '.' +ipConfig.ip_wan_address[3];

            }
            ip_wan_gateway = "";
            if (ipConfig.ip_wan_gateway[0] != null) {
                ip_wan_gateway = ipConfig.ip_wan_gateway[0]+ '.' + ipConfig.ip_wan_gateway[1] + '.' + ipConfig.ip_wan_gateway[2] + '.' + ipConfig.ip_wan_gateway[3];

            }
            ip_wan_netmask = "";
            if (ipConfig.ip_wan_netmask[0] != null) {
                ip_wan_netmask = ipConfig.ip_wan_netmask[0] + '.' + ipConfig.ip_wan_netmask[1] + '.' + ipConfig.ip_wan_netmask[2] + '.' + ipConfig.ip_wan_netmask[3];

            }
            ip_dns1="";
                    if (ipConfig.ip_dns1[0] != null) {
                ip_dns1 = ipConfig.ip_dns1[0] + '.' + ipConfig.ip_dns1[1] + '.' + ipConfig.ip_dns1[2] + '.' + ipConfig.ip_dns1[3];
            }
            ip_dns2 = "";
            if (ipConfig.ip_dns2[0] != null) {

                ip_dns2 = ipConfig.ip_dns2[0] + '.' + ipConfig.ip_dns2[1] + '.' + ipConfig.ip_dns2[2] + '.' + ipConfig.ip_dns2[3];
            }
        
             ip_config_req = '{"action":"update_ip_config","lan_ip":"'+lan_ip+'","wan_ip":"'+wan_ip+'","ip_dns1":"'+ip_dns1+'","hostname":"'+this.hostname+'","ip_dns2":"'+ip_dns2+'","ip_lan_gateway":"'+ip_lan_gateway+'","ip_lan_netmask":"'+ip_lan_netmask+'","ip_lan_address":"'+ip_lan_address+'","ip_wan_address":"'+ip_wan_address+'","ip_wan_gateway":"'+ip_wan_gateway+'","ip_wan_netmask":"'+ip_wan_netmask+'","command_type":"'+command_type+'"}';
       
            $('.loading').html('<h4>Your device is currently rebooting. The page will automatically refresh when it is operational again. If not please <a href="/#/ip-configuration" target="_blank" class="click-here">Click here</a> to reload the page.</h4>');
            $('.loading').css('display', 'block');
            setTimeout(() => { location.reload(); }, 120000);
            this.serverService.sendServer(ip_config_req).subscribe((response:any) => {
                 $('.loading').css('display', 'none');
                 this.router.navigate(['/ip-configuration']);
            }, 
            (error)=>{

                console.log(error);
            });
      
    }
    

    

    getIpConfigData() {
        
        $('.loading').css('display', 'block');
        
        var req_data =  '{"action":"getIPConfigData","hostname":"'+this.hostname+'"}';
    
        this.serverService.sendServer(req_data).subscribe((response:any) => {
            // $('.loading').css('display', 'none');
            var result_data =response;
            
            if (result_data.wan[0] == "static") {
                
                if(this.serverService.validateIP(result_data.wan[1])){
                     var wan_address = result_data.wan[1];
                     var wan_address_arr = wan_address.split('.');
                     this.ip_wan_address = wan_address_arr;
                }
                if(this.serverService.validateIP(result_data.wan[2])){
                     var wan_netmask = result_data.wan[2];
                      var wan_netmask_arr = wan_netmask.split('.');
                     this.ip_wan_netmask = wan_netmask_arr;
                }
                if(this.serverService.validateIP(result_data.wan[3])){
                    
                    var wan_gateway = result_data.wan[3];
                    var wan_gateway_arr = wan_gateway.split('.');
                    this.ip_wan_gateway = wan_gateway_arr; 
                }
                var wan_url = "http://" + wan_address + ":5015";
                $('#wan_ip').html('<a href="' + wan_url + '">' + wan_url + '</a>');
                document.getElementById('wan_option2').click();
                 this.wanOptionData = "manual";
            }
            else{
                document.getElementById('wan_option1').click();
               
               
                this.wanOptionData = "automatic";



                if(this.serverService.validateIP(result_data.wan[1])){
                    var wan_address = result_data.wan[1];
                    var wan_address_arr = wan_address.split('.');
                    this.ip_wan_address = wan_address_arr;
               }
               if(this.serverService.validateIP(result_data.wan[2])){
                    var wan_netmask = result_data.wan[2];
                     var wan_netmask_arr = wan_netmask.split('.');
                    this.ip_wan_netmask = wan_netmask_arr;
               }
               if(this.serverService.validateIP(result_data.wan[3])){
                   
                   var wan_gateway = result_data.wan[3];
                   var wan_gateway_arr = wan_gateway.split('.');
                   this.ip_wan_gateway = wan_gateway_arr; 
               }


            }
            
            if (result_data.lan[0] == "static") {
                
                 if(this.serverService.validateIP(result_data.lan[1])){
                    var lan_address = result_data.lan[1];
                    var lan_address_arr = lan_address.split('.');
                    this.ip_lan_address = lan_address_arr;
                 }
                
                if(this.serverService.validateIP(result_data.lan[2])){
                    var lan_netmask = result_data.lan[2];
                    var lan_netmask_arr = lan_netmask.split('.');
                    this.ip_lan_netmask = lan_netmask_arr;
                 }
                
                if(this.serverService.validateIP(result_data.lan[3])){
                    var lan_gateway = result_data.lan[3];
                    var lan_gateway_arr = lan_gateway.split('.');
                    this.ip_lan_gateway = lan_gateway_arr;
                 }
                
                var lan_url = "http://" + lan_address + ":5015";
                $('#lan_ip').html('<a href="' + lan_url + '">' + lan_url + '</a>');
                document.getElementById('lan_option2').click();
                this.lanOptionData = "manual";
            }
            else{
                document.getElementById('lan_option1').click();
                 
                 this.lanOptionData = "automatic";
                 if(this.serverService.validateIP(result_data.wan[1])){
                    var wan_address = result_data.wan[1];
                    var wan_address_arr = wan_address.split('.');
                    this.ip_wan_address = wan_address_arr;
               }
               if(this.serverService.validateIP(result_data.wan[2])){
                    var wan_netmask = result_data.wan[2];
                     var wan_netmask_arr = wan_netmask.split('.');
                    this.ip_wan_netmask = wan_netmask_arr;
               }
               if(this.serverService.validateIP(result_data.wan[3])){
                   
                   var wan_gateway = result_data.wan[3];
                   var wan_gateway_arr = wan_gateway.split('.');
                   this.ip_wan_gateway = wan_gateway_arr; 
               }
            }

            this.lanMACAddress = result_data.lanMACAddress.slice(4);
            this.wanMACAddress = result_data.wanMACAddress.slice(4);
            
            this.lanstatus = result_data.lanstatus;
            this.wanstatus = result_data.wanstatus;
            this.wanMACAddress = this.wanMACAddress.replace(/(..)/g, '$1:').slice(0,-1)
            this.lanMACAddress = this.lanMACAddress.replace(/(..)/g, '$1:').slice(0,-1)
            var dns = result_data.dns;
            var new_dns = [];
            var dns_key = 0;
            
            if(dns.length > 0){
                
                for(var dns_len=0; dns.length >dns_len; dns_len++){
                    
                    if(dns[dns_len] == "nameserver"){
                        
                        dns_key = 1;   
                    }
                    
                    if(dns_key == 1 && this.serverService.validateIP(dns[dns_len])){
                        
                        new_dns.push(dns[dns_len]);
                        
                    }
                    
                }
                
            }
            dns = new_dns;

            if (dns.length > 1) {
                if(this.serverService.validateIP(dns[0])){
                    var dns1_arr = dns[0].split('.');
                    this.ip_dns1 = dns1_arr;
                }
                
                if(this.serverService.validateIP(dns[1])){
                     var dns2_arr = dns[1].split('.');
                     this.ip_dns2 = dns2_arr;
                }

            }
            else if (dns.length == 1) {
             
                if(this.serverService.validateIP(dns[0])){
                    var dns1_arr = dns[0].split('.');
                    this.ip_dns1 = dns1_arr;
                }
                          
             }
            
            this.getIpBlockLoad();
            $('.version-info').html('<p>Version '+result_data.current_version+'</p>');
            localStorage.setItem('app_version', result_data.current_version);  
             this.ipConfigValidation();
             $('.loading').css('display', 'none');
        }, 
        (error)=>{

            console.log(error);
            
            this.getIpBlockLoad();
          
        });
        
        
    }
    
        getIpBlockLoad(){
                this.ip_wan_address.forEach((value) => {
                    const control_data = new FormControl(value, [Validators.required]);
                    (<FormArray>this.ipConfigurationData.get('ip_wan_address')).push(control_data);
                });

                this.ip_wan_netmask.forEach((value) => {
                    const control_data = new FormControl(value, [Validators.required]);
                    (<FormArray>this.ipConfigurationData.get('ip_wan_netmask')).push(control_data);
                });

                this.ip_wan_gateway.forEach((value) => {
                    const control_data = new FormControl(value, [Validators.required]);
                    (<FormArray>this.ipConfigurationData.get('ip_wan_gateway')).push(control_data);
                });

                this.ip_lan_address.forEach((value) => {
                    const control_data = new FormControl(value, [Validators.required]);
                    (<FormArray>this.ipConfigurationData.get('ip_lan_address')).push(control_data);
                });

                this.ip_lan_netmask.forEach((value) => {
                    const control_data = new FormControl(value, [Validators.required]);
                    (<FormArray>this.ipConfigurationData.get('ip_lan_netmask')).push(control_data);
                });

                this.ip_lan_gateway.forEach((value) => {
                    const control_data = new FormControl(value, [Validators.required]);
                    (<FormArray>this.ipConfigurationData.get('ip_lan_gateway')).push(control_data);
                });


              





                this.ip_dns1.forEach((value) => {
                    const control_data = new FormControl(value, [Validators.required]);
                    (<FormArray>this.ipConfigurationData.get('ip_dns1')).push(control_data);
                });

                this.ip_dns2.forEach((value) => {
                    const control_data = new FormControl(value, [Validators.required]);
                    (<FormArray>this.ipConfigurationData.get('ip_dns2')).push(control_data);
                });
            
                  this.lanOptions(this.lanOptionData);
                  this.wanOptions(this.wanOptionData);

            
        }
    

        showNetworkDetails(){
           
   
            console.log(this.aioNetworkDatas);
          
            var req_data = '{"action":"getNetworkLanSettings"}';
            this.serverService.sendAioServer(req_data).subscribe((response:any) => {

                console.log(response);
       
                
              this.openNetDet = true
              this.showConsole=false
              this.showLaunchPad = false
              var convert = require('xml-js');
              var xml =response.data;
              var result1 = convert.xml2json(xml, {compact: true, spaces: 4});
              result1 = JSON.parse(result1);
              const lanSett = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGNetworkGetResponse']['get']['lan'];
              console.log(lanSett);
            
                this.aioLanmac=lanSett['mac']['_text'];
                this.aioLanaddress=lanSett['ipaddr']['_text'];
                this.aioLannetmask=lanSett['netmask']['_text'];
                this.aioLangateway=lanSett['gateway']['_text'];
                this.aioLanmac="";
                this.aioLanaddress=lanSett['ipaddr']['_text'];
                this.aioLannetmask=lanSett['netmask']['_text'];
                this.aioLangateway=lanSett['gateway']['_text'];
    

              this.aioLantype=lanSett['type']['_text'];
              this.aioLanmac=lanSett['mac']['_text'];
              this.aioLanaddress=lanSett['ipaddr']['_text'];
              this.aioLannetmask=lanSett['netmask']['_text'];
              this.aioLangateway=lanSett['gateway']['_text'];
              console.log(this.aioLanaddress);

                if(this.aioLanaddress == '' || this.aioLanaddress == undefined){
                    this.aioLanaddress = '192.168.101.99';
                }
                if(this.aioLannetmask == '' || this.aioLannetmask == undefined){
                    this.aioLannetmask = '255.255.255.0';
                }
                if(this.aioLangateway == '' || this.aioLangateway == undefined){
                    this.aioLangateway = '192.168.101.1';
                }
                console.log(this.aioLanaddress);

                


              var aioLanaddress_arr = this.aioLanaddress.split('.');
              var aioLannetmask_arr = this.aioLannetmask.split('.');
              var aioLangateway_arr = this.aioLangateway.split('.');
              this.aioLan_Address = aioLanaddress_arr;
              this.aioLan_Netmask = aioLannetmask_arr;
              this.aioLan_Gateway = aioLangateway_arr;

             
              (<FormArray>this.aioNetworkDatas.get('aioLan_Address')).clear();
              (<FormArray>this.aioNetworkDatas.get('aioLan_Netmask')).clear();
              (<FormArray>this.aioNetworkDatas.get('aioLan_Gateway')).clear();
              
            this.aioLan_Address.forEach((value) => {
                const control_data = new FormControl(value, [Validators.required]);
                (<FormArray>this.aioNetworkDatas.get('aioLan_Address')).push(control_data);
            });
            console.log(this.aioNetworkDatas);
            this.aioLan_Netmask.forEach((value) => {
                const control_data = new FormControl(value, [Validators.required]);
                (<FormArray>this.aioNetworkDatas.get('aioLan_Netmask')).push(control_data);
            });
            this.aioLan_Gateway.forEach((value) => {
                const control_data = new FormControl(value, [Validators.required]);
                (<FormArray>this.aioNetworkDatas.get('aioLan_Gateway')).push(control_data);
            });

    
          
       
            },
            (error)=>{
                console.log(error);
            });
        }


        addLaunchPadList(){
         
            $('.loading').css('display', 'block');
            var data = this.aioNetworkDatas.value;

            this.ip_wan_address.forEach((value) => {
                const control_data = new FormControl(value, [Validators.required]);
                (<FormArray>this.aioNetworkDatas.get('aioLan_Address')).push(control_data);
            });

            this.ip_wan_netmask.forEach((value) => {
                const control_data = new FormControl(value, [Validators.required]);
                (<FormArray>this.aioNetworkDatas.get('aioLan_Netmask')).push(control_data);
            });

            this.ip_wan_gateway.forEach((value) => {
                const control_data = new FormControl(value, [Validators.required]);
                (<FormArray>this.aioNetworkDatas.get('aioLan_Gateway')).push(control_data);
            });

            data['action'] = 'saveLanSettings';
           
            data.aioLan_Address = data.aioLan_Address.join('.');
            data.aioLan_Netmask = data.aioLan_Netmask.join('.');
            data.aioLan_Gateway = data.aioLan_Gateway.join('.');
            data.aioLanmac = data.aioLanmac;

            var data2 = {};
            data2['action'] = 'saveLanSettings';
            data2['address'] = data.aioLan_Address;
            data2['netmask'] = data.aioLan_Netmask;
            data2['def_gateway'] = data.aioLan_Gateway;
            data2['mac'] = data.aioLanmac;
           var data3 = JSON.stringify(data2);
            console.log(data3);

          
            this.serverService.sendAioServer(data3).subscribe((response:any) => {
             
                var convert = require('xml-js');
              var xml =response.data;
              var result1 = convert.xml2json(xml, {compact: true, spaces: 4});
              result1 = JSON.parse(result1);
              const res = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGNetworkSaveResponse']['result']['_text'];
              console.log(result1);
              if(res == 0){
                $('.loading').css('display', 'none');
                swal("Success", 'Network Settings Saved Successfully', "success");
                this.aioNetworkDatas.reset();
                this.showNetworkDetails();
              } else {
                $('.loading').css('display', 'none');
                swal("Error", 'Sorry some error occured', "error");
              }
            },
        
            (error)=>{
                console.log(error);
            });
      
          }
                      
    

    

}
