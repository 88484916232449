import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { ServerService } from '../services/server.service';
declare var $:any;
declare var swal:any;
@Component({
  selector: 'app-commands',
  templateUrl: './commands.component.html',
  styleUrls: ['./commands.component.css']
})
export class CommandsComponent implements OnInit {
    hostname:string;
    sbcDetails:FormGroup;
    sbcDetailsFailover:FormGroup;
    sbcDetailsFailoverswitch:FormGroup;
    sbcEdit:FormGroup;
    usbStorage:FormGroup;
    deviceTypes:FormGroup;
    defaultRoutes:FormGroup;
    defaultProtocol:FormGroup;
    usb_format_status: boolean = false;
    usb_automatic_status: boolean = false;
    usb_storage_block: boolean = false;
    wizard_configuration: boolean = false;
    version_info:string;
    version_info_3cx:string;
    cloud_token: string;
    status_3cx:string;
    failover:any
    dportal;
    app_details:string;
    app_details2:string;
    checked: boolean = false;
    serial_number ='';
    cloudTok;
    o_sbc_pass:string;
    o_web_url:string;
    o_auth_key:string;
    o_PBX_SIP_Port:string;
    primstatus;
    o_PBX_Tunnel_Port:string;
    sbc_commands:string;
    has_edit:string;
    route_details:string;
    lan_details:string;
    wan_details:string;
    websocket;
    debianVersion;
    def_inteface = 'wan';
    sbcisActive;
    usb_storage_status:any = [{"usb1_mounted":"no","usb1_connected":"no","usb2_mounted":"no","usb2_connected":"no"}];
    usb_storages = [{'display_name' : 'USB 1', 'value':'usb1','id':'usb_storage1'},{'display_name' : 'USB 2', 'value':'usb2','id':'usb_storage2'}];
    usb_types = [{'display_name' : 'Mount to default 3CX storage path', 'value':'automatic','id':'usb_automatic'},{'display_name' : 'Mount to customized folder ', 'value':'manual','id':'usb_manual'}];
    device_type = [{'id':'3CX Installed','value':'3cx'},{'id':'3CX SBC Installed','value':'sbc'}]
    default_routes = [{'id':'wan'},{'id':'lan'}]
    interfaces = [{'id':'wan'},{'id':'lan'}]
    default_protocols = [{'id':'wan'},{'id':'lan'}]
    sbc_sets = [{'id':'primary'},{'id':'secondary'}]
    sbc_set_default = 'primary';
    ip_wan_address = '';
    ip_wan_netmask = '';
    ip_wan_gateway = '';
    primary_ip_wan_address = '';
    primary_ip_wan_netmask = '';
    primary_ip_wan_gateway = '';
    secondary_ip_wan_address = '';
    secondary_ip_wan_netmask = '';
    secondary_ip_wan_gateway = '';

    primary_ip_wan_address_u = '';
    primary_ip_wan_netmask_u = '';
    primary_ip_wan_gateway_u = '';
    secondary_ip_wan_address_u = '';
    secondary_ip_wan_netmask_u = '';
    secondary_ip_wan_gateway_u = '';

    constructor(private serverService: ServerService, private router:Router) { }
    recordingStorages =[];
    ngOnInit() {

        this.usbStorage = new FormGroup({
            'usb_storage' : new FormControl(null,Validators.required),
            'usb_type' : new FormControl(null,Validators.required),
            'recording_path' : new FormControl(null)
        });
         this.deviceTypes = new FormGroup({
            'device_type' : new FormControl(null,Validators.required),
        });
        this.defaultRoutes = new FormGroup({
            'default_route' : new FormControl(null,Validators.required),
        });
        this.defaultProtocol = new FormGroup({
            'default_protocal' : new FormControl(null,Validators.required),
        });
 this.usbStorageBlock();
        this.sbcDetails = new FormGroup({
               sbc_password: new FormControl(null,Validators.required),
               web_url: new FormControl(null,Validators.required),
               auth_key_id: new FormControl(null,Validators.required),
               pbx_sip_port: new FormControl(null,Validators.required),
               pbx_tunnel_port: new FormControl(null,Validators.required)
        });
        this.sbcDetailsFailover = new FormGroup({
            sbc_password: new FormControl(null,Validators.required),
            web_url: new FormControl(null,Validators.required),
            auth_key_id: new FormControl(null,Validators.required),
            pbx_sip_port: new FormControl(null,Validators.required),
            pbx_tunnel_port: new FormControl(null,Validators.required),
            primaryIP: new FormControl(null,Validators.required),
            primaryGateway: new FormControl(null,Validators.required),
            primaryNetmask: new FormControl(null,Validators.required),
            secondaryIP: new FormControl(null,Validators.required),
            secondaryGateway: new FormControl(null,Validators.required),
            secondaryNetmask: new FormControl(null,Validators.required),
            failover_timeout: new FormControl(null,Validators.required),
            interfaceName: new FormControl(null,Validators.required),
            primary: new FormControl(null,Validators.required)
     });
     this.sbcDetailsFailoverswitch = new FormGroup({

        primaryIP: new FormControl(null,Validators.required),
        primaryGateway: new FormControl(null,Validators.required),
        primaryNetmask: new FormControl(null,Validators.required),
        secondaryIP: new FormControl(null,Validators.required),
        secondaryGateway: new FormControl(null,Validators.required),
        secondaryNetmask: new FormControl(null,Validators.required),
        failover_timeout: new FormControl(null,Validators.required),
        interfaceName: new FormControl(null,Validators.required),
        primary: new FormControl(null,Validators.required)
 });
        this.sbcEdit = new FormGroup({
               edit_sbc_password: new FormControl(null,Validators.required),
               edit_web_url: new FormControl(null,Validators.required),
               edit_auth_key_id: new FormControl(null,Validators.required),
               edit_pbx_sip_port: new FormControl(null,Validators.required),
               edit_pbx_tunnel_port: new FormControl(null,Validators.required),
               edit_sbc1IP: new FormControl(null,Validators.required),
               edit_sbc2IP: new FormControl(null,Validators.required),
               edit_failover_timeout: new FormControl(null,Validators.required),
               edit_primary: new FormControl(null,Validators.required),
        });
        this.hostname = $(location).attr('hostname');
        this.version_info_3cx = "Latest version";
        this.status_3cx = "no";
        // this.app_details="sbc";
        // this.has_edit = "false";
      this.checkUsbDevice();
    //   this.getChecked();
    //   this.getCloudToken();
    //   this.getSerialNumber();
    this.initSocket();
    this.getIpConfigData()
    }



    initSocket(){
            this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4021");
            this.websocket.onopen = function(event) {
                console.log('socket chat connected');
            }
            this.websocket.onmessage = function(event) {
                $('#mc_event_list').val(event.data);
                $('#mc_event_list').click();

            }
            this.websocket.onerror = function(event){
                console.log('error');
            }
            this.websocket.onclose = function(event){
                console.log('close');
            }
    }





       checkStorageFormData(){
           var recordingStorages, usb_device;
            recordingStorages =  this.recordingStorages;
             if(recordingStorages.length > 0){
                 for(var device_count =0; recordingStorages.length >device_count;  device_count++){
                     usb_device = recordingStorages[device_count];
                     if(usb_device.usb_name == "usb1"){
                         $('#usb_storage1').attr('disabled',true);
                         this.usb_storage_status[0].usb1_mounted="yes";
                         if(usb_device.connected_folder == "/var/lib/3cxpbx/Instance1/Data/Recordings/"){
                             this.usb_automatic_status = true;
                             $('#usb_automatic').attr('disabled',true);
                         }
                     }
                     else if(usb_device.usb_name == "usb2"){
                      $('#usb_storage2').attr('disabled',true);
                         this.usb_storage_status[0].usb2_mounted="yes";
                         if(usb_device.connected_folder == "/var/lib/3cxpbx/Instance1/Data/Recordings/"){
                             this.usb_automatic_status = true;

                         }
                     }
                 }
             }
        }

    commands3cxApp(command_type){

        var message_detail, result_message;
        result_message = "Commands executed successfully!";
        if(command_type == "3cx_reset"){

            if(this.status_3cx == "yes" && this.wizard_configuration==true){
                swal("Warning", "Already 3CX installed and configured in your device", "warning");
                return false;
            }
            else if(this.status_3cx == "no"){

                swal("Error", "Sorry, 3CX not installed in your device", "error");
                return false;

            }
            else{
                message_detail ="Are you sure? You want to reset the 3CX?";
            }


        }
        else if(command_type == "network_reset"){
            message_detail ="Are you sure? You want to reset the network?";
        }
        else if(command_type == "reset_all"){
            message_detail ="Are you sure? You want to reset all?";
        }
        else if(command_type == "3cx_install"){
            message_detail ="Are you sure? You want to install 3CX?";
            result_message = "3CX installed successfully";
            if(this.status_3cx == "yes"){
                swal("Warning", "Already 3CX installed in your device", "warning");
                return false;
            }
        }
        else if(command_type == "3cx_uninstall"){
            message_detail ="Are you sure? 3CX will be Completely uninstalled no more config & Recording data will be available after uninstalling";
            result_message = "3CX uninstalled successfully";
            if(this.status_3cx == "no"){
                swal("Error", "Sorry, 3CX not installed in your device", "error");
                return false;
            }
        }
        else if(command_type == "power_off"){
            message_detail ="Are you sure? You want to power off the device?";
        }
        else if(command_type == "call4tel_reset"){
            message_detail ="Are you sure? You want to reset the call4tel app?";

        }
        else if(command_type == "reboot_device"){
            message_detail ="Are you sure? You want to reboot the device?";
        }
        else if(command_type == "call4teluninstall"){
            message_detail ="Are you sure? You want to unistall call4tell portal?";
        }
        else if(command_type == "debian_update"){
            result_message = "Debian Updated successfully";
            message_detail = "An early test version of V18 is available. This includes an upgrade from Debian 9 to 10. This update should only be applied to test systems or systems that can afford some downtime! Take a backup before applying this upgrade. If this is a live system wait for the beta or final version.";
        }

          if(confirm(message_detail)) {

                var commands_req = '{"action":"commands_3cx","command_type":"'+command_type+'","hostname":"'+this.hostname+'"}';

                if(command_type == "power_off"){
                    $('.loading').html('<h4>Your device will shut down...</h4>');
                } else if(command_type == "call4teluninstall"){
                    $('.loading').html('<h4>Your device will reboot...</h4>');
                } else if(command_type == "reboot_device"){
                    $('.loading').html('<h4>Your device is currently rebooting. The page will automatically refresh when it is operational again. If not please <a href="/#/3cx" target="_blank" class="click-here">Click here</a> to reload the page.</h4>');
                } else if(command_type == "debian_update"){
                    $('.loading').html('<h4>Debian/3CX Updating</h4>');
                } else {
                    $('.loading').html('<h4>Loading...</h4>');
                }


                    setTimeout(() => { location.reload(); }, 120000);

                $('.loading').css('display', 'block');

                this.serverService.sendServer(commands_req).subscribe((response:any) => {
                    var result_data = response;
                    if(command_type == 'debian_update'){
                        $('.loading').html('<h4>Debian/3CX Updating</h4>');
                        setTimeout(() => { location.reload(); }, 100000);
                    } else{
                        if(result_data.updateCommand == ''){
                            swal("Already Updated", '', "success");
                        } else {
                            swal("Success", result_message, "success");
                        }
                        $('.loading').css('display', 'none');
                        $('.loading').html('Loading...');
                    }

                },
                (error)=>{
                    console.log(error);
                   // $('.loading').css('display', 'none');
                });


            }


    }


    commands3cxAppData(command_type){

        var message_detail;

       var commands_req = '{"action":"commands_3cx_data","command_type":"'+command_type+'","hostname":"'+this.hostname+'"}';


            $('.loading').css('display', 'block');

        this.serverService.sendServer(commands_req).subscribe((response:any) => {

            var result_data = response;

            this.version_info_3cx = "V"+result_data.version_info_3cx
            $('.loading').css('display', 'none');
        },
        (error)=>{
            console.log(error);
        });


    }


    checkUsbDevice(){
      let deviceTypes:any = this.deviceTypes.value;
       var storage_req = '{"action":"check_usb_device","hostname":"'+this.hostname+'","internet":"yes"}';
       $('.loading').css('display', 'block');
       this.serverService.sendServer(storage_req).subscribe((response:any) => {
        $('.loading').css('display', 'none');

           if(response.version_info == ""){
               this.version_info ="Version not available";
           }
           else{
               this.version_info = "V "+response.version_info;
           }

           if(response.wizard_conf == 0 || response.wizard_conf == "0"){
               this.wizard_configuration = true;
           }

           this.cloudTok = response.base64_bytes;

           if(response.usb1 == "yes"){
                $('#usb_storage1').attr('disabled',false);
                 this.usb_storage_status[0].usb1_connected="yes";
            }
           else{
                $('#usb_storage1').attr('disabled',true);
               this.usb_storage_status[0].usb1_connected="no";

            }

           if(response.usb2 == "yes"){
                $('#usb_storage2').attr('disabled',false);
               this.usb_storage_status[0].usb2_connected="yes";
            }
           else{
                $('#usb_storage2').attr('disabled',true);
               this.usb_storage_status[0].usb2_connected="no";

            }



            if (response.socket_process == "allowed") {
                this.checked = true;

            }
            else {
                this.checked = false;
            }

            const failover = response.app_details
            const subfailover = "Failover"
           var dt = failover.includes(subfailover);



            this.recordingStorages = response.usb_data;
            this.status_3cx = response.status_3cx;
            this.app_details = response.app_details2;
          //  this.app_details = response.app_details;
            this.dportal = response.default_portal;
            this.o_sbc_pass = response.sbc_pass;
            this.o_web_url = response.web_url;
            this.primstatus = response.primstatus;
            this.o_auth_key = response.auth_key;
            this.sbcisActive = response.sbcisActive;
            this.lan_details = response.lan_details;
            this.wan_details = response.wan_details;
            this.o_PBX_SIP_Port=response.pbx_sip_port;
            this.o_PBX_Tunnel_Port = response.pbx_tunnel_port;
            this.has_edit = response.has_edit;
            this.serial_number = response.serial_num;
            this.debianVersion = response.debianVersion;
            this.resetStorageForm();
            this.checkStorageFormData();
            if(response.route_config.route === "empty"){
                this.route_details = "";
            } else{
                this.route_details = response.route_config.route;

            }
            if(dt == true){
              this.app_details ='failoversbc';
            }
              // alert(this.app_details);
        },
        (error)=>{
            console.log(error);
            this.checkUsbDeviceNoInternet();
            $('.loading').css('display', 'none');
            swal("Warning", "We could not verify your device, Please check your internet", "warning");
        });


    }


    checkUsbDeviceNoInternet(){

        var storage_req = '{"action":"check_usb_device","hostname":"'+this.hostname+'","internet":"no"}';
        $('.loading').css('display', 'block');
        this.serverService.sendServer(storage_req).subscribe((response:any) => {
         $('.loading').css('display', 'none');

            if(response.version_info == ""){
                this.version_info ="Version not available";
            }
            else{
                this.version_info = "V "+response.version_info;
            }

            if(response.wizard_conf == 0 || response.wizard_conf == "0"){
                this.wizard_configuration = true;
            }

            this.cloudTok = response.base64_bytes;

            if(response.usb1 == "yes"){
                 $('#usb_storage1').attr('disabled',false);
                  this.usb_storage_status[0].usb1_connected="yes";
             }
            else{
                 $('#usb_storage1').attr('disabled',true);
                this.usb_storage_status[0].usb1_connected="no";

             }

            if(response.usb2 == "yes"){
                 $('#usb_storage2').attr('disabled',false);
                this.usb_storage_status[0].usb2_connected="yes";
             }
            else{
                 $('#usb_storage2').attr('disabled',true);
                this.usb_storage_status[0].usb2_connected="no";

             }



             if (response.socket_process == "allowed") {
                 this.checked = true;

             }
             else {
                 this.checked = false;
             }


             this.recordingStorages = response.usb_data;
             this.status_3cx = response.status_3cx;
             this.app_details = response.app_details;
             this.dportal = response.default_portal;
             this.o_sbc_pass = response.sbc_pass;
             this.o_web_url = response.web_url;
             this.primstatus = response.primstatus;
             this.o_auth_key = response.auth_key;
             this.sbcisActive = response.sbcisActive;
             this.lan_details = response.lan_details;
             this.wan_details = response.wan_details;
             this.o_PBX_SIP_Port=response.pbx_sip_port;
             this.o_PBX_Tunnel_Port = response.pbx_tunnel_port;
             this.has_edit = response.has_edit;
             this.serial_number = 'No Internet';
             this.debianVersion = response.debianVersion;
             this.resetStorageForm();
             this.checkStorageFormData();
             if(response.route_config.route === "empty"){
                 this.route_details = "";
             } else{
                 this.route_details = response.route_config.route;

             }


         },
         (error)=>{
             console.log(error);
             $('.loading').css('display', 'none');
         });


     }













    checkCustomizedFolder(usb_type){

        if(usb_type == "automatic"){

            $('#recording_path').attr("readonly",true).val("").addClass("input-hide");
        }
        else if(usb_type == "manual"){

            $('#recording_path').attr("readonly",false).removeClass("input-hide");;

        }

    }


    checkUsbStatus(usb_storage){

        var alert_message = "";
        if(usb_storage == "usb1"){

            if(this.usb_storage_status[0].usb1_connected == "no"){

                alert_message = "USB 1 not connected";

            }
            else{
                if(this.usb_storage_status[0].usb1_mounted=="yes"){
                    alert_message = "USB 1 already mounted";
                }
            }
        }
        else if(usb_storage == "usb2"){

            if(this.usb_storage_status[0].usb2_connected == "no"){
                alert_message = "USB 2 not connected";
            }
            else{
                if(this.usb_storage_status[0].usb2_mounted=="yes"){
                    alert_message = "USB 2 already mounted";
                }
            }

        }
        if(alert_message != ""){
            alert(alert_message);
            swal("Info", alert_message, "info");
        }

    }

    // connectCloud(){


    //     var enabled = $('#conCloud').is(":checked");

    //     // $('.loading').css('display', 'block');
    //     if (enabled == true) {
    //         var cloud_req = '{"action":"connectCloud"}';
    //         this.serverService.sendServer(cloud_req).subscribe((response:any) => {
    //             console.log(response);
    //             var result_data = response;
    //             var message = result_data.message;
    //             if (message == "Cloud Token received") {
    //                 swal("Success", result_data.message, "success");
    //                 this.cloud_token = result_data.token;
    //                 this.serial_number = result_data.serial_number;
    //             } else if (message == "please activate this device via web UI") {
    //                 swal("Warning", result_data.message, "warning");
    //             }

    //         // $('.loading').css('display', 'none');
    //         },
    //         (error)=>{
    //             console.log(error);
    //         });
    //     }
    //     else {
    //         var cloud_req = '{"action":"disconnectCloud"}';
    //         this.serverService.sendServer(cloud_req).subscribe((response:any) => {
    //             console.log(response);
    //             var result_data = response;
    //             this.cloud_token = result_data.message;
    //             swal("Info", result_data.message, "info");
    //         // $('.loading').css('display', 'none');
    //         },
    //         (error)=>{
    //             console.log(error);
    //         });
    //     }


    // }

    // getChecked() {
    //     var req = '{"action":"isConnected"}';
    //     this.serverService.sendServer(req).subscribe((response:any) => {
    //         console.log(response);
    //         var result = response;
    //         if (response.message == "full") {
    //             this.checked = true;
    //         }
    //         else {
    //             this.checked = false;
    //         }
    //     },
    //     (error) => {
    //         console.log(error);
    //     });
    // }
    // getCloudToken() {
    //     var req = '{"action":"cloudToken"}';
    //     this.serverService.sendServer(req).subscribe((response:any) => {
    //         console.log(response);
    //         var result = response;
    //         this.cloud_token = result.cloud_token;
    //     },
    //     (error) => {
    //         console.log(error);
    //     });
    // }
    // getSerialNumber() {
    //     var req = '{"action":"serialNumber"}';
    //     this.serverService.sendServer(req).subscribe((response:any) => {
    //         console.log(response);
    //         var result = response;
    //         this.serial_number = result.serial_number;
    //     },
    //     (error) => {
    //         console.log(error);
    //     });
    // }

    checkUsbtype(usb_storage){

        $('#usb_manual').attr('disabled',false);

        if(this.usb_automatic_status == false){

               var storage_req = '{"action":"check_usb_path","usb_storage":"'+usb_storage+'","hostname":"'+this.hostname+'"}';
                 $('.loading').css('display', 'block');
               this.serverService.sendServer(storage_req).subscribe((response:any) => {

                    if(response.avilable_status == "yes"){
                        $('#usb_automatic').attr('disabled',false);
                    }
                   else{
                        $('#usb_automatic').attr('disabled',true);
                        $("#usb_manual").click();
                    }
                    $('.loading').css('display', 'none');
                },
                (error)=>{
                    console.log(error);
                });

        }
        else{

                    $("#usb_manual").click();
        }

    }

    formatStorage(){

        let usbStorage:any = this.usbStorage.value;

         if(usbStorage.usb_storage == null){
             swal("Error", "Please choose any one usb storage", "error");
            return false;
         }

        if(confirm("All the storage will be removed. Do you want to continue Yes / No")) {


                var storage_req = '{"action":"formatStorage","usb_storage":"'+usbStorage+'","hostname":"'+this.hostname+'"}';
                     $('.loading').css('display', 'block');
                   this.serverService.sendServer(storage_req).subscribe((response:any) => {

                       if(response.status == 1){
                           swal("Success", "The usb storage deleted successfully", "success");
                           this.usb_format_status = true;
                       }

                        $('.loading').css('display', 'none');
                    },
                    (error)=>{
                        console.log(error);
                    });

        }

    }


    removeStoragePath(usb_id){

         var req_data = '{"action":"removeStorageDevice","usb_id":'+usb_id+',"hostname":"'+this.hostname+'"}';
       $('.loading').css('display', 'block');

        this.serverService.sendServer(req_data).subscribe((response:any) => {

            if(response.status == 1){
                 this.resetStorageForm();
                 this.checkUsbDevice();
                 swal("Success", "Storage path unmounted successfully", "success");
                 $('.loading').css('display', 'none');
            }


        },
        (error)=>{

            console.log(error);
        });

    }

    usbStorageValidation(){
         let usbStorage:any = this.usbStorage.value;

         if(usbStorage.usb_storage == null){
            swal("Error", "Please choose any one usb storage", "error");
            return false;
         }
         if(usbStorage.usb_type == null){

            swal("Error", "Please choose any one storage type", "error");
            return false;
         }
         if(usbStorage.usb_type =="manual" && (usbStorage.recording_path == null || usbStorage.recording_path == "")){
            swal("Error", "Please enter the valid recording path", "error");
            return false;
         }

        return true;

    }

    setstoragePath(){

        let usbStorage:any = this.usbStorage.value;

        var validation_data;

        validation_data = this.usbStorageValidation();

        if (validation_data != true) {
            return false;
        }

       var storage_req = '{"action":"update_storage_path","usb_storage":"'+usbStorage.usb_storage+'","usb_type":"'+usbStorage.usb_type+'","recording_path":"'+usbStorage.recording_path+'","hostname":"'+this.hostname+'"}';
        $('.loading').css('display', 'block');
       this.serverService.sendServer(storage_req).subscribe((response:any) => {

           if(response.status == 1){
               this.resetStorageForm();
                swal("Success", "The recording path successfully updated.", "success");
           }
           $('.loading').css('display', 'none');
           this.checkUsbDevice();
           this.router.navigate(['/3cx']);
        },
        (error)=>{
            console.log(error);
        });

    }

    usbStorageBlock(){
        if(this.usb_storage_block == false){
            this.usb_storage_block = false;
        }
        else{
            this.usb_storage_block  = false;
        }

    }



    resetStorageForm(){
        this.usbStorage.reset();
    }



   closeModal(){
       $('#openModal-about').css('display', 'none');
       $('#openModal-abouts').css('display', 'none');
       $('#openModal-failover').css('display', 'none');
       $('#openModal-failoverSwitch').css('display', 'none');
       $('#openModal-edit').css('display', 'none');
       $('#openModal-runFailover').css('display', 'none');

        var i = this.app_details;
        $('#'+i).click();
       this.sbcDetails.reset();
    }

    sbcConfigDetailsValidation(){
         let sbcDetails:any = this.sbcDetails.value;
         var re = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;

         if(sbcDetails.sbc_password == null){
            swal("Error", "Please enter the password", "error");
            return false;
         }
         // if(!re.test(sbcDetails.web_url))
         if(sbcDetails.web_url == null){
            swal("Error", "Please enter the valid 3cx web url", "error");
            return false;
         }
          if(sbcDetails.auth_key_id == null){
            swal("Error", "Please enter the valid authentication key id", "error");
            return false;
         }
         if(sbcDetails.pbx_sip_port == null){
            swal("Error", "Please enter the valid PBX SIP Port", "error");
            return false;
         }
         if(sbcDetails.pbx_tunnel_port == null){
            swal("Error", "Please enter the valid PBX Tunnel Port", "error");
            return false;
         }
        return true;
    }
    sbcConfigEditValidation(){
         let sbcEdit:any = this.sbcEdit.value;

      // var re = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-])(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
         if(sbcEdit.edit_sbc_password == null){
            swal("Error", "Please enter the password", "error");
            return false;
         }

            if(sbcEdit.edit_web_url == null){
            swal("Error", "Please enter the valid 3cx web url", "error");
            return false;
         }
          if(sbcEdit.edit_auth_key_id == null){
            swal("Error", "Please enter the valid authentication key id", "error");
            return false;
         }
         if(sbcEdit.edit_pbx_sip_port == null || sbcEdit.edit_pbx_sip_port == "undefined" ){
            swal("Error", "Please enter the valid PBX SIP Port", "error");
            return false;
         }
         if(sbcEdit.edit_pbx_tunnel_port == null || sbcEdit.edit_pbx_tunnel_port == "undefined" ){
            swal("Error", "Please enter the valid PBX Tunnel Port", "error");
            return false;
         }
        return true;
    }
deviceDetail(name){
        console.log(this.app_details);
        if(name != this.app_details){
            $('#change_button').css('display', 'block');
        }
        else {
             $('#change_button').css('display', 'none');
        }
}


sbcEditBlock(command_type){
    console.log(command_type);
         if(this.app_details == "3CX SBC Installed"){
            this.sbc_commands = command_type;
            $('#openModal-edit').css('display', 'block');
        }
         else{
            $('#openModal-edit').css('display', 'none');
         }
}

sbcEditBlock2(command_type){
    console.log(command_type);
         if(this.app_details == "failoversbc"){
            this.sbc_commands = command_type;
            $('#openModal-edit').css('display', 'block');
        }
         else{
            $('#openModal-edit').css('display', 'none');
         }
}
sbcEditDetails(){
    let sbcEdit:any = this.sbcEdit.value;
    var validation_data;
    validation_data = this.sbcConfigEditValidation();
        if (validation_data != true) {
            return false;
    }

    var parser = document.createElement('a');
    parser.href = sbcEdit.edit_web_url;
    var sbchostname = parser.hostname;
    var prov_link = sbcEdit.edit_web_url+"/sbc/"+sbcEdit.edit_auth_key_id;
    var  req_data = '{"action":"3cxsbcedit","sbc_password":"'+sbcEdit.edit_sbc_password+'","tunnelAddr":"'+sbchostname+'","pbx_sip_ip":"'+sbchostname+'","prov_link":"'+prov_link+'","hostname":"'+this.hostname+'","web_url":"'+sbcEdit.edit_web_url+'","auth_key":"'+sbcEdit.edit_auth_key_id+'","sbc_command":"'+this.sbc_commands+'","pbx_sip_port":"'+sbcEdit.edit_pbx_sip_port+'","pbx_tunnel_port":"'+sbcEdit.edit_pbx_tunnel_port+'"}';
    console.log(req_data);
    $('.loading').html('<h4>Please wait, We are uninstalling your 3CX Phone System & Installing 3CX SBC the system will take more than 180 seconds to back to operations please refresh after 180 seconds..</h4>');
            $('.loading').css('display', 'block');
            setTimeout(() => { history.go(0); }, 180000);
    this.serverService.sendServer(req_data).subscribe((response:any) => {
    if(response.status == 1){
        $('.loading').css('display', 'none');
        this.checkUsbDevice();
        $('#openModal-about').css('display', 'none');
        $('#openModal-edit').css('display', 'none');
        $('#openModal-abouts').css('display', 'none');
        this.sbcEdit.reset();
    } else {
        swal("Error", "SBC already installed in this device", "error");
            return false;
    }
    },
    (error)=>{
    console.log(error);
    });
    this.sbcDetails.reset();
}


    currentDeviceType(){
         let deviceTypes:any = this.deviceTypes.value;
         console.log(deviceTypes.device_type);




         if(deviceTypes.device_type == "3CX SBC Installed"){
            $('#openModal-about').css('display', 'block');
        } else if(deviceTypes.device_type == "failoversbc"){
            $('#openModal-failover').css('display', 'block');
            this.changeProtocal('primary');
        } else if(deviceTypes.device_type != "failoversbc"){
            $('#openModal-abouts').css('display', 'block');
         }
    }



    sbcConfigDetails(){
    let sbcDetails:any = this.sbcDetails.value;
    var validation_data;
    validation_data = this.sbcConfigDetailsValidation();
        if (validation_data != true) {
            return false;
    }
    var parser = document.createElement('a');
    parser.href = sbcDetails.web_url;
    var sbchostname = parser.hostname;
    var prov_link = sbcDetails.web_url+"/sbc/"+sbcDetails.auth_key_id;

   var  req_data = '{"action":"3cxsbc_config","sbc_password":"'+sbcDetails.sbc_password+'","tunnelAddr":"'+sbchostname+'","pbx_sip_ip":"'+sbchostname+'","prov_link":"'+prov_link+'","hostname":"'+this.hostname+'","web_url":"'+sbcDetails.web_url+'","auth_key":"'+sbcDetails.auth_key_id+'","pbx_sip_port":"'+sbcDetails.pbx_sip_port+'","pbx_tunnel_port":"'+sbcDetails.pbx_tunnel_port+'"}';

    $('.loading').html('<h4>Please wait, We are uninstalling your 3CX Phone System & Installing 3CX SBC the system will take more than 180 seconds to back to operations please refresh after 180 seconds..</h4>');
            $('.loading').css('display', 'block');
             setTimeout(() => { history.go(0); }, 180000);
    this.serverService.sendServer(req_data).subscribe((response:any) => {
    if(response.status == 1){

        $('#change_button').css('display', 'none');
        $('#edit_button').css('display', 'block');
        $('.loading').css('display', 'none');
        $('#openModal-about').css('display', 'none');
        $('#openModal-abouts').css('display', 'none');
         this.checkUsbDevice();
        this.sbcDetails.reset();
    }
    },
    (error)=>{
    console.log(error);
    });
    this.sbcDetails.reset();

  }


    uninstallSBC(){
        swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, uninstall it!'
        }).then(()=> {

           var req_data = '{"action":"sbc_uninstall","hostname":"'+this.hostname+'"}';
                  $('.loading').css('display', 'block');
                this.serverService.sendServer(req_data).subscribe((response:any) => {
                if(response.status == 1){
                    $('.loading').css('display', 'none');
                    $('#openModal-about').css('display', 'none');
                    $('#openModal-abouts').css('display', 'none');
                    $('#change_button').css('display', 'block');
                    $('#edit_button').css('display', 'none');
                    this.checkUsbDevice();
                    this.sbcDetails.reset();
                }
                },
                (error)=>{
                console.log(error);
                });

        }, function(dismiss) {
          if (dismiss === 'cancel' || dismiss === 'close') {
           console.log(dismiss);
          }
        })
    }


    switchTo3cx(){
       var req_data = '{"action":"sbc_to_3cx","hostname":"'+this.hostname+'"}';
        $('.loading').html('<h4>Please wait, We are uninstalling your 3CX SBC & Installing 3CX Phone System, the system will take more than 180 seconds to back to operations please refresh the page after reboot the system..</h4>');
            $('.loading').css('display', 'block');
            setTimeout(() => { history.go(0); }, 360000);
        this.serverService.sendServer(req_data).subscribe((response:any) => {
        if(response.status == 1){
            this.app_details = '';
            this.checkUsbDevice();
            $('.loading').css('display', 'none');
            $('#openModal-about').css('display', 'none');
            $('#openModal-abouts').css('display', 'none');
            $('#change_button').css('display', 'block');
            $('#edit_button').css('display', 'none');
            this.checkUsbDevice();
            this.sbcDetails.reset();
        }
        },
        (error)=>{
        console.log(error);
        });
    }


    changeDeviceRoute(){
        let defaultRoutes:any = this.defaultRoutes.value;
        var defRoute = defaultRoutes.default_route;
        var lan_gatw = this.lan_details[3];
        var wan_gatw = this.wan_details[3];
        if(defRoute == "wan" && wan_gatw ==undefined){
            swal("Error", "Sorry, Please Update the gateway in WAN on IP Configuration.", "error");
            return false;
        }
        if(defRoute == "lan" && lan_gatw ==undefined){
            swal("Error", "Sorry, Please Update the gateway in LAN on IP Configuration.", "error");
            return false;
        }

        var req_data = '{"action":"changeDefaultPort","new_route":"'+defRoute+'","hostname":"'+this.hostname+'","lan_gateway":"'+lan_gatw+'","wan_gateway":"'+wan_gatw+'"}';
       console.log(req_data);
       //return false;
        $('.loading').css('display', 'block');
       this.serverService.sendServer(req_data).subscribe((response:any) => {
           $('.loading').css('display', 'none');
           this.checkUsbDevice();
           if(response){
               swal("Success", "Default route has been updated.", "success");
           }
           else{
               swal("Error", "Sorry, Default route not updated.", "error");
           }
       },
       (error)=>{

           console.log(error);
       });
   }

   showHide(){
    $(".toggle-password").toggleClass("fa-eye fa-eye-slash");
    var input = $("#pass_log_id");
    if (input.attr("type") === "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
   }

   connectSocketCloud(){
    var enabled = $('#conCloud').is(":checked");
    if (enabled == true) {
        var cloud_req = '{"action":"connectSocketCloud","hostname":"'+this.hostname+'"}';
        $('.loading').html('<h4>Your device is currently rebooting. The page will automatically refresh when it is operational again. If not please <a href="/#/3cx" target="_blank" class="click-here">Click here</a> to reload the page.</h4>');
        setTimeout(() => { location.reload(); }, 120000);
        $('.loading').css('display', 'block');
        var data = '{"action":"cloudConnectionEnabled", "uid":"'+this.cloudTok+'"}';
        this.websocket.send(data);
        this.serverService.sendServer(cloud_req).subscribe((response:any) => {
            $('.loading').css('display', 'none');
            $('.loading').html('Loading...');
        },
        (error)=>{
            console.log(error);
        });
    }
    else {

        var cloud_req = '{"action":"disconnectSocketCloud","hostname":"'+this.hostname+'"}';
        $('.loading').html('<h4>Your device is currently rebooting. The page will automatically refresh when it is operational again. If not please <a href="/#/3cx" target="_blank" class="click-here">Click here</a> to reload the page.</h4>');
        setTimeout(() => { location.reload(); }, 120000);
        $('.loading').css('display', 'block');
        var data = '{"action":"cloudConnectionDisabled", "uid":"'+this.cloudTok+'"}';
        this.websocket.send(data);
        this.serverService.sendServer(cloud_req).subscribe((response:any) => {
            $('.loading').css('display', 'none');
            $('.loading').html('Loading...');
        },
        (error)=>{
            console.log(error);
        });
    }


}
routeDetails(name){
    if(name != this.dportal){
        $('#route_change_button').css('display', 'block');
    }
    else {
         $('#route_change_button').css('display', 'none');
    }
}
cahngeProtocalType(){
    let defaultProtocals:any = this.defaultProtocol.value;
    var defRoute = defaultProtocals.default_protocal;
    var req_data = '{"action":"changeDefaultProtocal","new_route":"'+defRoute+'","hostname":"'+this.hostname+'"}';
   console.log(req_data);
    $('.loading').css('display', 'block');
   this.serverService.sendServer(req_data).subscribe((response:any) => {

       $('#route_change_button').css('display', 'none');

            console.log(response);
            if( response.commandm == "WAN interface is Default" || response.commandm == "LAN interface is Default"){
                this.checkUsbDevice();
                swal("Success", response.rc , "success");
            } else {
                $('.loading').css('display', 'none');
                swal("Error", response.rc, "error");
                var i = this.dportal;
                $('#'+i).click();
                return false;
            }

   },
   (error)=>{

       console.log(error);
   });
}







sbcConfigDetailss(){
    let sbcDetails:any = this.sbcDetailsFailover.value;
    // var validation_data;
    // validation_data = this.sbcConfigDetailsValidation();
    //     if (validation_data != true) {
    //         return false;
    // }
    var parser = document.createElement('a');
    parser.href = sbcDetails.web_url;
    var sbchostname = parser.hostname;
    var prov_link = sbcDetails.web_url+"/sbc/"+sbcDetails.auth_key_id;

   var  req_data = '{"action":"3cxsbcfailover_config","sbc_password":"'+sbcDetails.sbc_password+'","tunnelAddr":"'+sbchostname+'","pbx_sip_ip":"'+sbchostname+'","prov_link":"'+prov_link+'","hostname":"'+this.hostname+'","web_url":"'+sbcDetails.web_url+'","auth_key":"'+sbcDetails.auth_key_id+'","pbx_sip_port":"'+sbcDetails.pbx_sip_port+'","pbx_tunnel_port":"'+sbcDetails.pbx_tunnel_port+'","primaryIP":"'+sbcDetails.primaryIP+'","primaryGateway":"'+sbcDetails.primaryGateway+'","primaryNetmask":"'+sbcDetails.primaryNetmask+'","secondaryIP":"'+sbcDetails.secondaryIP+'","secondaryGateway":"'+sbcDetails.secondaryGateway+'","secondaryNetmask":"'+sbcDetails.secondaryNetmask+'","failover_timeout":"'+sbcDetails.failover_timeout+'","primary":"'+sbcDetails.primary+'","interfaceName":"'+sbcDetails.interfaceName+'"}';

console.log(req_data);


    $('.loading').html('<h4>Please wait, We are uninstalling your 3CX Phone System & Installing 3CX SBC the system will take more than 180 seconds to back to operations please refresh after 180 seconds..</h4>');
            $('.loading').css('display', 'block');
           setTimeout(() => { history.go(0); }, 180000);
           $('#change_button').css('display', 'none');
           $('#edit_button').css('display', 'block');
           $('#openModal-about').css('display', 'none');
           $('#openModal-abouts').css('display', 'none');
           $('#openModal-failover').css('display', 'none');
    this.serverService.sendServer(req_data).subscribe((response:any) => {
    if(response.status == 1){
        $('#change_button').css('display', 'none');
        $('#edit_button').css('display', 'block');
        $('.loading').css('display', 'none');
        $('#openModal-about').css('display', 'none');
        $('#openModal-abouts').css('display', 'none');
        $('#openModal-failover').css('display', 'none');
         this.checkUsbDevice();
        this.sbcDetails.reset();
    }
    },
    (error)=>{
    console.log(error);
    });
    this.sbcDetails.reset();

  }




primarySwitch(defRoute){
    this.closeModal();
    this.changeProtocalU('primary');
    $('#openModal-failoverSwitch').css('display', 'block');
}


primarySwitchUpdate(){
    let sbcDetails:any = this.sbcDetailsFailoverswitch.value;
    var  req_data = '{"action":"primarySecondarySwitch","hostname":"'+this.hostname+'","primaryIP":"'+sbcDetails.primaryIP+'","primaryGateway":"'+sbcDetails.primaryGateway+'","primaryNetmask":"'+sbcDetails.primaryNetmask+'","secondaryIP":"'+sbcDetails.secondaryIP+'","secondaryGateway":"'+sbcDetails.secondaryGateway+'","secondaryNetmask":"'+sbcDetails.secondaryNetmask+'","failover_timeout":"'+sbcDetails.failover_timeout+'","primary":"'+sbcDetails.primary+'","interfaceName":"'+sbcDetails.interfaceName+'"}';

    console.log(req_data);
    this.closeModal();
    $('.loading').css('display', 'block');
   this.serverService.sendServer(req_data).subscribe((response:any) => {
        console.log(response);
        $('.loading').css('display', 'none');
        swal("Success", 'Updated Successfully', "success");
        this.checkUsbDevice();
   },
   (error)=>{

       console.log(error);
   });
}

stopFailover(){
    var req_data = '{"action":"stopFailover","hostname":"'+this.hostname+'"}';
    console.log(req_data);
    $('.loading').css('display', 'block');
   this.serverService.sendServer(req_data).subscribe((response:any) => {
        console.log(response);
        $('.loading').css('display', 'none');
        swal("Success", 'SBC Failover Disabled', "success");
        this.checkUsbDevice();
   },
   (error)=>{

       console.log(error);
   });
}


runFailover(){
    this.closeModal();
    $('#openModal-runFailover').css('display', 'block');
}

runFailovers(current){
    this.closeModal();
    var req_data = '{"action":"runFailover","hostname":"'+this.hostname+'","current":"'+current+'"}';
    console.log(req_data);
    $('.loading').css('display', 'block');
   this.serverService.sendServer(req_data).subscribe((response:any) => {
        console.log(response);
        $('.loading').css('display', 'none');
        swal("Success", 'SBC Failover Enabled', "success");
        this.checkUsbDevice();

   },
   (error)=>{

       console.log(error);
   });

}

getIpConfigData() {

    $('.loading').css('display', 'block');

    var req_data =  '{"action":"getIPConfigData","hostname":"'+this.hostname+'"}';

    this.serverService.sendServer(req_data).subscribe((response:any) => {

        var result_data =response;

        if (result_data.wan[0] == "static") {

            if(this.serverService.validateIP(result_data.wan[1])){
                 var wan_address = result_data.wan[1];
                 var wan_address_arr = wan_address.split('.');
                 wan_address_arr = wan_address_arr.toString().replaceAll(",",".");
            }
            if(this.serverService.validateIP(result_data.wan[2])){
                 var wan_netmask = result_data.wan[2];
                  var wan_netmask_arr = wan_netmask.split('.');
                  wan_netmask_arr = wan_netmask_arr.toString().replaceAll(",",".");
            }
            if(this.serverService.validateIP(result_data.wan[3])){
                var wan_gateway = result_data.wan[3];
                var wan_gateway_arr = wan_gateway.split('.');
                wan_gateway_arr = wan_gateway_arr.toString().replaceAll(",",".");
            }

        } else{

            if(this.serverService.validateIP(result_data.wan[1])){
                var wan_address = result_data.wan[1];
                var wan_address_arr = wan_address.split('.');
                wan_address_arr = wan_address_arr.toString().replaceAll(",",".");
           }
           if(this.serverService.validateIP(result_data.wan[2])){
                var wan_netmask = result_data.wan[2];
                 var wan_netmask_arr = wan_netmask.split('.');
                 wan_netmask_arr = wan_netmask_arr.toString().replaceAll(",",".");
           }
           if(this.serverService.validateIP(result_data.wan[3])){

               var wan_gateway = result_data.wan[3];
               var wan_gateway_arr = wan_gateway.split('.');
               wan_gateway_arr = wan_gateway_arr.toString().replaceAll(",",".");
           }


        }
            this.ip_wan_address = wan_address_arr;
            this.ip_wan_netmask = wan_netmask_arr;
            this.ip_wan_gateway = wan_gateway_arr;

    },
    (error)=>{

        console.log(error);


    });


}

changeProtocal(type){
    const req = this.sbcDetailsFailover.value;

    if(type == 'primary' && req.interfaceName == 'wan'){
        this.primary_ip_wan_address = this.ip_wan_address;
        this.primary_ip_wan_netmask = this.ip_wan_netmask;
        this.primary_ip_wan_gateway = this.ip_wan_gateway;
        this.secondary_ip_wan_address = '';
        this.secondary_ip_wan_netmask = '';
        this.secondary_ip_wan_gateway = '';
    } else if(type == 'secondary' && req.interfaceName == 'wan'){
        this.secondary_ip_wan_address = this.ip_wan_address;;
        this.secondary_ip_wan_netmask = this.ip_wan_netmask;
        this.secondary_ip_wan_gateway = this.ip_wan_gateway;
        this.primary_ip_wan_address = '';
        this.primary_ip_wan_netmask = '';
        this.primary_ip_wan_gateway = '';
    } else {
        this.primary_ip_wan_address = '';
        this.primary_ip_wan_netmask = '';
        this.primary_ip_wan_gateway = '';
        this.secondary_ip_wan_address = '';
        this.secondary_ip_wan_netmask = '';
        this.secondary_ip_wan_gateway = '';
    }

}

changeProtocalInterface(type){
    const req = this.sbcDetailsFailover.value;

    if(req.primary == 'primary' && type == 'wan'){
        this.primary_ip_wan_address = this.ip_wan_address;
        this.primary_ip_wan_netmask = this.ip_wan_netmask;
        this.primary_ip_wan_gateway = this.ip_wan_gateway;
        this.secondary_ip_wan_address = '';
        this.secondary_ip_wan_netmask = '';
        this.secondary_ip_wan_gateway = '';
    } else if(req.primary == 'secondary' && type == 'wan'){
        this.secondary_ip_wan_address = this.ip_wan_address;;
        this.secondary_ip_wan_netmask = this.ip_wan_netmask;
        this.secondary_ip_wan_gateway = this.ip_wan_gateway;
        this.primary_ip_wan_address = '';
        this.primary_ip_wan_netmask = '';
        this.primary_ip_wan_gateway = '';
    } else {
        this.primary_ip_wan_address = '';
        this.primary_ip_wan_netmask = '';
        this.primary_ip_wan_gateway = '';
        this.secondary_ip_wan_address = '';
        this.secondary_ip_wan_netmask = '';
        this.secondary_ip_wan_gateway = '';
    }
}

changeProtocalU(type){
    const req = this.sbcDetailsFailover.value;

    if(type == 'primary' && req.interfaceName == 'wan'){
        this.primary_ip_wan_address_u = this.ip_wan_address;
        this.primary_ip_wan_netmask_u = this.ip_wan_netmask;
        this.primary_ip_wan_gateway_u = this.ip_wan_gateway;
        this.secondary_ip_wan_address_u = '';
        this.secondary_ip_wan_netmask_u = '';
        this.secondary_ip_wan_gateway_u = '';
    } else if(type == 'secondary' && req.interfaceName == 'wan'){
        this.secondary_ip_wan_address_u = this.ip_wan_address;;
        this.secondary_ip_wan_netmask_u = this.ip_wan_netmask;
        this.secondary_ip_wan_gateway_u = this.ip_wan_gateway;
        this.primary_ip_wan_address_u = '';
        this.primary_ip_wan_netmask_u = '';
        this.primary_ip_wan_gateway_u = '';
    } else {
        this.primary_ip_wan_address_u = '';
        this.primary_ip_wan_netmask_u = '';
        this.primary_ip_wan_gateway_u = '';
        this.secondary_ip_wan_address_u = '';
        this.secondary_ip_wan_netmask_u = '';
        this.secondary_ip_wan_gateway_u = '';
    }

}



changeProtocalInterfaceU(type){
    const req = this.sbcDetailsFailover.value;

    if(req.primary == 'primary' && type == 'wan'){
        this.primary_ip_wan_address_u = this.ip_wan_address;
        this.primary_ip_wan_netmask_u = this.ip_wan_netmask;
        this.primary_ip_wan_gateway_u = this.ip_wan_gateway;
        this.secondary_ip_wan_address_u = '';
        this.secondary_ip_wan_netmask_u = '';
        this.secondary_ip_wan_gateway_u = '';
    } else if(req.primary == 'secondary' && type == 'wan'){
        this.secondary_ip_wan_address_u = this.ip_wan_address;;
        this.secondary_ip_wan_netmask_u = this.ip_wan_netmask;
        this.secondary_ip_wan_gateway_u = this.ip_wan_gateway;
        this.primary_ip_wan_address_u = '';
        this.primary_ip_wan_netmask_u = '';
        this.primary_ip_wan_gateway_u = '';
    } else {
        this.primary_ip_wan_address_u = '';
        this.primary_ip_wan_netmask_u = '';
        this.primary_ip_wan_gateway_u = '';
        this.secondary_ip_wan_address_u = '';
        this.secondary_ip_wan_netmask_u = '';
        this.secondary_ip_wan_gateway_u = '';
    }
}


}
