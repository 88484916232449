import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';

@Component({
  selector: 'app-ssh',
  templateUrl: './ssh.component.html',
  styleUrls: ['./ssh.component.css']
})
export class SshComponent implements OnInit {
  sshform:FormGroup;
  hostname:string;
  sshForm: FormGroup | any
  data:any
  constructor(private route:Router, private serverService: ServerService, private formBul:FormBuilder) { }

  ngOnInit() {

  }

 }






