import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
@Injectable()
export class ServerService {


    IP_REGEXP = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    constructor(private http:HttpClient){

    }
    sendServer(postData:string) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        };

        let post_data = new HttpParams({
          fromObject : JSON.parse(postData)
        });

        // return this.http.post("http://172.16.8.94:5448/main.py",post_data);
      //  return this.http.post("http://172.16.8.111:5448/main.py",post_data);
     return this.http.post("main.py",post_data);
    // return this.http.post("http://172.16.8.236:5448/main.py",post_data);
       var dn = window.location.hostname;
       dn = "http://"+dn+":5448/main.py"
       return this.http.post(dn,post_data);
    }

  sendAioServer(postData:string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
// return this.http.post("http://172.16.8.236/main.php",postData);
    var dn = window.location.hostname;
    dn = "http://"+dn+"/main.php"
    return this.http.post(dn, postData);
    return this.http.post("main.php",postData, httpOptions);
   }


    validateIP(ip:string) {
        var result = this.IP_REGEXP.test(ip);
        return result;
    }
}


