import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var $:any;
declare var swal:any;
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
    user_name:string;
    user_password:string;
    profileChange:FormGroup;  
    user_profile: boolean = true;
    user_change_profile: boolean = false;
    current_password_show: boolean = false;
    new_password_show: boolean = false;
    profileChangeSuccess:string;
    profileChangeError:string;
    hostname:string;

  constructor(private serverService: ServerService, private router:Router) { }
  
  ngOnInit() {
        this.hostname = $(location).attr('hostname'); 
        this.profileChange = new FormGroup({
            'old_password' : new FormControl(null,Validators.required),
            'new_password' : new FormControl(null,Validators.required),
            'action' : new FormControl("change_profile"),
            'hostname' : new FormControl(this.hostname)
        });
      
      this.getUserInfo();
      
  }
    
  getUserInfo(){
      
        $('.loading').css('display', 'block');
        var version_req = '{"action":"getUserdetails"}';
        this.serverService.sendServer(version_req).subscribe((response:any) => {
            
            var result_data = response;
            if(result_data.status == 1){
                
                this.user_name = result_data.user_name;
                this.user_password = result_data.password;
                
            }
            
            $('.loading').css('display', 'none');
        }, 
        (error)=>{
            console.log(error);
        });

  }
    
    changeProfile(){
        this.user_profile = false;
        this.user_change_profile = true;
    }
    
    updateUserProfile(){
        
        var profile_details = this.profileChange.value;
         profile_details = JSON.stringify(profile_details);
           this.serverService.sendServer(profile_details).subscribe((response:any) => {
           if(response.status == 1){
                this.profileChangeError="";
                this.profileChangeSuccess = "You have successfully changed your password";
                this.profileChange.reset();
                swal("Success", "Password updated successfully", "success");
                this.user_profile = true;
                this.user_change_profile = false;
                this.router.navigate(['/logout']);

            }
            else{

                 this.profileChangeSuccess = "";
                 this.profileChangeError="You should enter the valid current password.";

            }

        }, 
        (error)=>{

            console.log(error);
        });
        
    }
    
    currentPwdShow(){
        
        if(this.current_password_show == false){
            
            this.current_password_show = true;
            $('#old_password').attr('type','text');
            
        }
        else{
            this.current_password_show = false;
             $('#old_password').attr('type','password');
        }
        
    }
    
    newPwdShow(){
        
        if(this.new_password_show == false){
            
             this.new_password_show = true;
             $('#new_password').attr('type','text');
            
        }
        else{
             this.new_password_show = false;
            $('#new_password').attr('type','password');
        }
        
    }


}


