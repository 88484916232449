import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
declare var $:any;
declare var swal:any;
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  hostname;
  constructor(private serverService: ServerService) { }
    

  ngOnInit() {
  
    this.hostname = $(location).attr('hostname');
  
  }

  RunThisCommand(){
    $('.loading').css('display', 'block');
    var command = $('#command').val();
    var req_data = '{"action":"getContactURL","hostname":"'+this.hostname+'"}';
    this.serverService.sendServer(req_data).subscribe((response:any) => {
        $('.loading').css('display', 'none');
        console.log(response);
        if(response.status == 1){
          window.open(response.result, '_blank');
        }
      }, 
      (error)=>{
          console.log(error);
      });
} 
    

   

}
