import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var $:any;
declare var swal:any;
@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.css']
})
export class UpdateComponent implements OnInit {
hostname:string; 
appUpdate: boolean = false;
appversionUpdateBtn:string; 
  constructor(private serverService: ServerService, private router:Router) { }

  ngOnInit() {
      
      this.hostname = $(location).attr('hostname');
      this.appversionUpdateBtn = "Click here to update the app";
      this.getAppVersion();
     
  }
    
    
    getAppVersion(){
        
       $('.loading').css('display', 'block');  
       var version_req = '{"action":"getAppVersionUpdate"}';
        this.serverService.sendServer(version_req).subscribe((response:any) => {
            var result_data = response;
               if(result_data.current_version != result_data.server_version){
                  this.appUpdate = true; 
                   this.appversionUpdateBtn = 'Click here to update the app (version '+result_data.server_version+')';
               }
            $('.loading').css('display', 'none');
        }, 
        (error)=>{
            console.log(error);
            $('.loading').css('display', 'none');
            swal("Warning", "We could not verify your device, Please check your internet", "warning");
        });
        
    }
    
    getVersionUpdate(){
        $('.loading').css('display', 'block');  
        var version_req = '{"action":"versionUpdate","hostname":"'+this.hostname+'"}';
        this.serverService.sendServer(version_req).subscribe((response:any) => {
            var result_data = response;
            if(result_data.status == 1){
               this.appUpdate = true;
                localStorage.setItem('app_version', result_data.current_version); 
                swal("Success", "File updated successfully", "success");
                $('.loading').css('display', 'none');  
               this.router.navigate(['/']);
            }
        }, 
        (error)=>{
            console.log(error);
            this.getAppVersion();
            $('.loading').css('display', 'none');
            swal("Warning", "We could not verify your device, Please check your internet", "warning");
        });

    }
    

}
