import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule }  from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { IpConfigurationComponent } from './ip-configuration/ip-configuration.component';
import { FirewallComponent } from './firewall/firewall.component';
import { SipConnectComponent } from './sip-connect/sip-connect.component';
import { CommandsComponent } from './commands/commands.component';
import { WarrantyComponent } from './warranty/warranty.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { UpdateComponent } from './update/update.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { ServerService } from './services/server.service';
import { MenuComponent } from './menu/menu.component';
import { LogoutComponent } from './logout.component';
import { RunCommandsComponent } from './run-commands/run-commands.component';
import { SshComponent } from './ssh/ssh.component';
import { AioComponent } from './aio/aio.component';
import { SafePipe } from './safe.pipe';
import { FooterComponent } from './footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatChipsModule} from '@angular/material/chips';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {DragDropModule} from '@angular/cdk/drag-drop';
const appRoutes: Routes = [
    {
        path :'', component:HomeComponent
    },
    {
        path :'ip-configuration', component:IpConfigurationComponent
    },{
        path :'firewall', component:FirewallComponent
    },{
      path :'ssh', component:SshComponent
    },{
        path :'sip-connect', component:SipConnectComponent
    },{
        path :'settings', component:CommandsComponent
    },{
        path :'warranty', component:WarrantyComponent
    },{
        path :'contact-us', component:ContactUsComponent
    },{
        path :'login', component:LoginComponent
    },{
        path :'update', component:UpdateComponent
    },{
        path :'profile', component:ProfileComponent
    },
    {
        path :'logout', component:LogoutComponent
    },
    {
        path :'run-commands-by-admin', component:RunCommandsComponent
    },{
        path :'aio', component:AioComponent
    },
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    IpConfigurationComponent,
    FirewallComponent,
    SipConnectComponent,
    CommandsComponent,
    WarrantyComponent,
    ContactUsComponent,
    UpdateComponent,
    LoginComponent,
    ProfileComponent,
    MenuComponent,
    LogoutComponent,
    RunCommandsComponent,
    SshComponent,
    AioComponent,
    SafePipe,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes,{ useHash: true }),
    BrowserAnimationsModule,
    MatChipsModule,
    MatInputModule, MatIconModule,DragDropModule
  ],
  providers: [ServerService],
  bootstrap: [AppComponent]
})
export class AppModule { }
