import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var $:any;
@Component({
  selector: 'app-warranty',
  templateUrl: './warranty.component.html',
  styleUrls: ['./warranty.component.css']
})
export class WarrantyComponent implements OnInit {
  hostname:string;
  warranty_details;
  warranty_data;
  constructor(private serverService: ServerService, private router:Router) { }

  ngOnInit() {
  this.hostname = $(location).attr('hostname');
  this.checkWarranty();
  }

  checkWarranty(){
  		var warrant_check = '{"action":"check_warranty","hostname":"'+this.hostname+'"}';
          $('.loading').css('display', 'block');
        this.serverService.sendServer(warrant_check).subscribe((response:any) => {
           
           if(Object.keys(response.data).length > 0){

	           	var nx32_support = response.data.nx32_support;

	           	if(nx32_support == "valid"){
	           		this.warranty_data = "valid";
	           		this.warranty_details ="Warranty Available from "+response.data.issue_date+" to "+response.data.expiry_date;
	           	}
	           	else{
	           		this.warranty_data = "invalid";
	           		
	           	}

           }
           else{
           		this.warranty_data = "not_activated";
           		
           }
             
            $('.loading').css('display', 'none');
            
        }, 
        (error)=>{
            console.log(error);
        });

  }


  RunThisCommand(){
    $('.loading').css('display', 'block');
    var command = $('#command').val();
    var req_data = '{"action":"getContactURL","hostname":"'+this.hostname+'"}';
    this.serverService.sendServer(req_data).subscribe((response:any) => {
        $('.loading').css('display', 'none');
        console.log(response);
        if(response.status == 1){
          window.open(response.result, '_blank');
        }
      }, 
      (error)=>{
          console.log(error);
      });
} 


}
