import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    

     loginForm:FormGroup;
   
    constructor(private serverService: ServerService, private router:Router) { }
    loginError = "";
    loginSuccess = "";

      ngOnInit() {
          
            this.loginForm = new FormGroup({
                'user_name' : new FormControl(null,Validators.required),
                'pwd' : new FormControl(null,Validators.required),
                'action' : new FormControl("user_login")
            });
            
            if(localStorage.getItem('access_user')) {
                this.router.navigate(['/']);
                return true;
            }
            // localStorage.setItem('access_user', 'root');  
            // this.router.navigate(['/']);
          
      }

  userLogin(){

        let loginReq:any = this.loginForm.value;
        loginReq = JSON.stringify(loginReq);
                this.serverService.sendServer(loginReq).subscribe((response:any) => {
       
                    
                        if(response.status==1){
                            localStorage.setItem('access_user', response.user_name);  
                            this.loginError="";
                            this.loginSuccess = "You have successfully logged in";
                            this.loginForm.reset();
                            this.router.navigate(['/']);

                        }
                        else{

                             this.loginSuccess = "";
                             this.loginError="Please enter the valid username and password";

                        }

                    }, 
                    (error)=>{
                        console.log(error);
                    });
    

  }

}
