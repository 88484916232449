import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'call4tel';
    
     constructor(private router:Router) { }
    
  onActivate(event) {
      window.scroll(0,0);
       if (localStorage.getItem('access_user')) {
           
            return true;
        }
      
     this.router.navigate(['/login']);
      
       return true;
    }
  

}
