import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var $:any;
declare var swal:any;
@Component({
  selector: 'app-sip-connect',
  templateUrl: './sip-connect.component.html',
  styleUrls: ['./sip-connect.component.css']
})
export class SipConnectComponent implements OnInit {
  private direct_ip: string[] = [null, null, null,null];
  private ip_range: string[] = [null,null,null,null,null];
  hostname:string;
  route_changes =[];

  allow_type = [{'id':'direct', 'allowd_type' : 'Allow IP'},{'id':'range', 'allowd_type' : 'Allow IP Range'}];
  interfaces = [{'id':'lan', 'name' : 'Lan'},{'id':'wan', 'name' : 'Wan'}];
  sipConnectRoute:FormGroup;
  constructor(private serverService: ServerService, private router:Router) { }

  ngOnInit() {

        this.sipConnectRoute = new FormGroup({
            'static_route' : new FormControl(),
            'allow_type' : new FormControl(),
            'interface' : new FormControl(),
            'direct_ip': new FormArray([]), 
            'ip_range' : new FormArray([]) 
        });
	
          this.direct_ip.forEach((value) => {
          const control_data = new FormControl(null, [Validators.required]);
          (<FormArray>this.sipConnectRoute.get('direct_ip')).push(control_data);
        });
        
        this.ip_range.forEach((value) => {
          const control_data = new FormControl(null, [Validators.required]);
          (<FormArray>this.sipConnectRoute.get('ip_range')).push(control_data);
        });
      
      this.hostname = $(location).attr('hostname');
      
      this.getSipConnectData();

  }

  removeRouteChanges(route_id){
      
    var req_data = '{"action":"removeSipRouteCmd","route_key_id":'+route_id+',"hostname":"'+this.hostname+'"}';
   $('.loading').css('display', 'block');
    this.serverService.sendServer(req_data).subscribe((response:any) => {

        if(response.status == 1){
            this.getSipConnectData();
            swal("Success", "Sip connect details removed successfully", "success");
             $('.loading').css('display', 'none');
        }
    }, 
    (error)=>{

        console.log(error);
    });

  }
    
    
    getSipConnectData(){
        
        var req_data = '{"action":"getSipConnectData"}';
        $('.loading').css('display', 'block');
        this.serverService.sendServer(req_data).subscribe((response:any) => {
            this.route_changes = response;
            $('.loading').css('display', 'none');
        }, 
        (error)=>{

            console.log(error);
        });
        
        
    }



  sipIpType(allow_type){
      
      
    if (allow_type == "direct") {

        $(".ip_range").each(function () {
            var ip_range = $(this);
            $(ip_range).val('');
            $(ip_range).attr('readonly', true);
            $(ip_range).removeClass('ip_data');
            $(ip_range).addClass('ip_hide');
        });

        $(".direct_ip").each(function () {
            var ip_range = $(this);
            $(ip_range).attr('readonly', false);
            $(ip_range).addClass('ip_data');
            $(ip_range).removeClass('ip_hide');
        });

    } else if (allow_type == "range") {

        $(".direct_ip").each(function () {
            var ip_range = $(this);
            $(ip_range).val('');
            $(ip_range).attr('readonly', true);
            $(ip_range).removeClass('ip_data');
            $(ip_range).addClass('ip_hide');
        });

        $(".ip_range").each(function () {
            var ip_range = $(this);
            $(ip_range).attr('readonly', false);
            $(ip_range).addClass('ip_data');
            $(ip_range).removeClass('ip_hide');
        });


    }
  
  }
    
    sipConnectValidation(){
        
        var sipConnectForm = this.sipConnectRoute.value;
        
        if(sipConnectForm.allow_type == null){
             swal("Error", "Please choose any one ip type", "error");
            return false;
        }
        if(sipConnectForm.interface == null){
             swal("Error", "Please choose any one interface type (lan/wan)", "error");
            return false;
        }
        
        var ip_data_status = true;

        $(".ip_data").each(function () {
            var ip_data = $(this);
            if (ip_data.val() == "") {
                $(ip_data).addClass('invalid_ip_data');
                ip_data_status = false;
            }
            
        });

    return ip_data_status;
        

    }

  updateSipConnectDetails(){
      
    var validation_data = this.sipConnectValidation();

    if (validation_data != true) {
        return false;
    }
          
    let sipConnectReq:any = this.sipConnectRoute.value;
    var static_route, allow_type, direct_ip, ip_range, interface_data,sip_connect_req;
    static_route = sipConnectReq.static_route;
    allow_type = sipConnectReq.allow_type;
    interface_data = sipConnectReq.interface;
      
     if(sipConnectReq.direct_ip[0] != null){
    direct_ip= sipConnectReq.direct_ip[0]+'.'+sipConnectReq.direct_ip[1]+'.'+sipConnectReq.direct_ip[2]+'.'+sipConnectReq.direct_ip[3];
     }
    else{
        direct_ip = "";
    }
    if(sipConnectReq.ip_range[0] != null){
        ip_range = sipConnectReq.ip_range[0]+'.'+sipConnectReq.ip_range[1]+'.'+sipConnectReq.ip_range[2]+'.'+sipConnectReq.ip_range[3]+'/'+sipConnectReq.ip_range[4];
    }
    else{
        ip_range = "";
    }
      sip_connect_req = '{"action":"update_sip_connect","allow_type":"'+allow_type+'","direct_ip":"'+direct_ip+'","ip_range":"'+ip_range+'","hostname":"'+this.hostname+'","static_route":"'+static_route+'","interface":"'+interface_data+'"}';
      
         $('.loading').css('display', 'block');
         
    this.serverService.sendServer(sip_connect_req).subscribe((response:any) => {


            if(response.status == 1){
                this.resetSipConnectDetails();
                this.getSipConnectData();
                swal("Success", "Sip connect details updated successfully", "success");
                $('.loading').css('display', 'none');
                this.router.navigate(['/sip-connect']);
            } else if(response.status == "no-static-ip"){
                swal("Error", "please update static IP address on "+interface_data, "error");
                $('.loading').css('display', 'none');
            } 
           
        }, 
        (error)=>{

            console.log(error);
        });


  }

  resetSipConnectDetails(){
    this.sipConnectRoute.reset();
  }


}
