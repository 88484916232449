import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var $:any;
declare var swal:any;

@Component({
  selector: 'app-run-commands',
  templateUrl: './run-commands.component.html',
  styleUrls: ['./run-commands.component.css']
})
export class RunCommandsComponent implements OnInit {
  hostname:string;
  commandResult:string;
  commandRuns = false; 
  constructor(private serverService: ServerService, private router:Router) { }

  ngOnInit() {
    this.hostname = $(location).attr('hostname');
    if(localStorage.getItem('access_user') == 'admin') {
      
    } else{
      this.router.navigate(['/']);
      return true;
    }
  }

  RunThisCommand(){
    $('.loading').css('display', 'block');
    var command = $('#command').val();
    var req_data = '{"action":"runCommandCustomCommands","hostname":"'+this.hostname+'","command":"'+command+'"}';
    this.commandRuns = false; 
    this.serverService.sendServer(req_data).subscribe((response:any) => {
    $('.loading').css('display', 'none');
    var result_data = response;
    if(result_data.status == 1){
        this.commandResult = result_data.result;
        $('.loading').css('display', 'none'); 
        this.commandRuns = true; 
    } else {
      this.commandRuns = true; 
    }
    }, 
    (error)=>{
        console.log(error);
    });
}


}
