import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var $:any;
declare var swal:any;
@Component({
  selector: 'app-firewall',
  templateUrl: './firewall.component.html',
  styleUrls: ['./firewall.component.css']
})
export class FirewallComponent implements OnInit {
private direct_ip: string[] = [null, null, null,null];
private ip_range: string[] = [null,null,null,null,null];
hostname:string;
firewallAccess:FormGroup;
allow_type = [{'id':'all_ip', 'allowd_type' : 'Allow All'},{'id':'direct', 'allowd_type' : 'Allow IP'},{'id':'range', 'allowd_type' : 'Allow IP Range'}];
list_protocal = [{'id':'tcp', 'allowd_type' : 'TCP'},{'id':'udp', 'allowd_type' : 'UDP'},{'id':'both', 'allowd_type' : 'Both'}];   
  firewall_changes =[];
  sip_port_data = "";
   mode = "";
   allowedPorts;
   app_details = 'tcp';
    constructor(private serverService: ServerService, private router:Router) { }

    ngOnInit() {

        this.firewallAccess = new FormGroup({
            'allow_type' : new FormControl('all_ip'),
            'direct_ip' : new FormArray([]), 
            'ip_range' : new FormArray([]) 
        });
        
        this.direct_ip.forEach((value) => {
          const control_data = new FormControl(null, [Validators.required]);
          (<FormArray>this.firewallAccess.get('direct_ip')).push(control_data);
        });
        
        this.ip_range.forEach((value) => {
          const control_data = new FormControl(null, [Validators.required]);
          (<FormArray>this.firewallAccess.get('ip_range')).push(control_data);
        });
        
        this.hostname = $(location).attr('hostname');
    
        this.getfirewallData();

    }
    
    sipPortChange(sip_port){
         
   
        if (!(/^[0-9]+$/.test(sip_port)) || sip_port.length < 4 || sip_port.length > 5) {
            $('#sip_port').val('');
            swal("Error", " Please enter the valid port number. It should be min 4 digit and max 5 digit", "error");
            return false;
        }
        
        
        var req_data = '{"action":"updateSipPortChange","new_port":"'+sip_port+'","hostname":"'+this.hostname+'"}';
         $('.loading').css('display', 'block');
        this.serverService.sendServer(req_data).subscribe((response:any) => {
            
            if(response.status == 1){
                swal("Success", "Port change updated successfully.", "success");
            }
            else{
                swal("Error", "Sorry, port change not updated. Please enter the valid port number.", "error");
            }
            
            $('.loading').css('display', 'none');
        }, 
        (error)=>{

            console.log(error);
        });
        
  
    }
    
    getfirewallData(){
        
        var req_data = '{"action":"getFirewallData"}';
         $('.loading').css('display', 'block');
        

        this.serverService.sendServer(req_data).subscribe((response:any) => {
            
           
                this.firewall_changes = response.data;
                this.sip_port_data = response.sip_port;
                this.mode = response.mode;
                this.allowedPorts = response.port_data;
                document.getElementById(response.mode).click();
            
            $('.loading').css('display', 'none');
        }, 
        (error)=>{

            console.log(error);
        });
        
        
    }
      
 firewallValidation() {

    var ip_data_status = true;

    $(".ip_data").each(function () {
        var ip_data = $(this);
        if (ip_data.val() == "") {
            $(ip_data).addClass('invalid_ip_data');
            ip_data_status = false;
        }
    });

    return ip_data_status;


}
    
removeFireallChanges(firewall_id){
    
    if(this.firewall_changes.length > 1){
    
      var req_data = '{"action":"removeFirewallCmd","firewall_key_id":'+firewall_id+',"hostname":"'+this.hostname+'"}';
       $('.loading').css('display', 'block');
        this.serverService.sendServer(req_data).subscribe((response:any) => {

            if(response.status == 1){
                 this.getfirewallData();
                 swal("Success", "Firewall settings removed successfully", "success");
                 $('.loading').css('display', 'none');
            }

        }, 
        (error)=>{

            console.log(error);
        });
        
    }
    else{
        swal("Error", "Sorry, you should have atleast one firewall rule.", "error");
    }
}
    
firewallExternalAccess(){
    
    let firewallAccess:any = this.firewallAccess.value;
    var allow_type, direct_ip, ip_range,validation_data,req_data;
    allow_type = firewallAccess.allow_type;
     if(firewallAccess.direct_ip[0] != null){
    direct_ip= firewallAccess.direct_ip[0]+'.'+firewallAccess.direct_ip[1]+'.'+firewallAccess.direct_ip[2]+'.'+firewallAccess.direct_ip[3];
     }
    else{
        direct_ip = "";
    }
    if(firewallAccess.ip_range[0] != null){
        ip_range = firewallAccess.ip_range[0]+'.'+firewallAccess.ip_range[1]+'.'+firewallAccess.ip_range[2]+'.'+firewallAccess.ip_range[3]+'/'+firewallAccess.ip_range[4];
    }
    else{
        ip_range = "";
    }
  
     validation_data = this.firewallValidation();
    

    if (validation_data != true) {
        return false;
    }
    
    req_data = '{"action":"updateFirewallData","allow_type":"'+allow_type+'","direct_ip":"'+direct_ip+'","ip_range":"'+ip_range+'","hostname":"'+this.hostname+'"}';
    
            $('.loading').css('display', 'block');
         
    this.serverService.sendServer(req_data).subscribe((response:any) => {

            this.resetFirewallDetails();
            this.getfirewallData();
            this.router.navigate(['/firewall']);
            swal("Success", "Firewall settings updated successfully", "success");
            $('.loading').css('display', 'none');
        
        }, 
        (error)=>{

            console.log(error);
        });
    
}
   
firewallType(allow_type){

    if (allow_type == "direct") {

        $(".ip_range").each(function () {
            var ip_range = $(this);
            $(ip_range).val('');
            $(ip_range).attr('readonly', true);
            $(ip_range).removeClass('ip_data');
            $(ip_range).addClass('ip_hide');
        });

        $(".direct_ip").each(function () {
            var ip_range = $(this);

            $(ip_range).attr('readonly', false);
            $(ip_range).addClass('ip_data');
            $(ip_range).removeClass('ip_hide');
        });

    } else if (allow_type == "range") {

        $(".direct_ip").each(function () {
            var ip_range = $(this);
            $(ip_range).val('');
            $(ip_range).attr('readonly', true);
            $(ip_range).removeClass('ip_data');
            $(ip_range).addClass('ip_hide');
        });

        $(".ip_range").each(function () {
            var ip_range = $(this);
            $(ip_range).attr('readonly', false);
            $(ip_range).addClass('ip_data');
            $(ip_range).removeClass('ip_hide');
        });


    } else if (allow_type == "all_ip") {

        $(".direct_ip,.ip_range").each(function () {

            var ip_range = $(this);
            $(ip_range).val('');
            $(ip_range).attr('readonly', true);
            $(ip_range).removeClass('ip_data');
            $(ip_range).addClass('ip_hide');
        });

    }
}

  resetFirewallDetails(){
    this.firewallAccess.reset();
    this.firewallAccess.patchValue({
        'allow_type' : 'all_ip'
    });
  }
    
  toAllowPort(sip_port){
         
    var protocal = $("input[name='list_protocal_se']:checked").val();

    if (!(/^[0-9]+$/.test(sip_port)) || sip_port.length < 1 || sip_port.length > 7) {
        $('#sip_port').val('');
        swal("Error", " Please enter the valid port number. It should be min 1 digit and max 7 digit", "error");
        return false;
    }
    // console.log(sip_port); return false;
    
    var req_data = '{"action":"allowNewPortChange","new_port":"'+sip_port+'","hostname":"'+this.hostname+'","protocal":"'+protocal+'"}';
     $('.loading').css('display', 'block');
    this.serverService.sendServer(req_data).subscribe((response:any) => {
        $('.loading').css('display', 'none');
        if(response){
            swal("Success", "New Port Allowed successfully.", "success");
            this.allowedPorts = response.app_data[0].data;
            $('#allow_port').val('');
        }
        else{
            swal("Error", "Sorry, port not updated. Please enter the valid port number.", "error");
        }
        
      
    }, 
    (error)=>{

        console.log(error);
    });

}


removeAllowedPort(sip_port, protocal){    
   
    var req_data = '{"action":"removePortChange","new_port":"'+sip_port+'","hostname":"'+this.hostname+'","protocal":"'+protocal+'"}';
     $('.loading').css('display', 'block');
    this.serverService.sendServer(req_data).subscribe((response:any) => {
        $('.loading').css('display', 'none');
        if(response){
            swal("Success", "Port Removed successfully.", "success");
            this.allowedPorts = response.app_data[0].data;
        }
        else{
            swal("Error", "Sorry, port not updated. Please enter the valid port number.", "error");
        }
        
      
    }, 
    (error)=>{

        console.log(error);
    });  
}

}
