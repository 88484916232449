import { Component, HostListener, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Pipe, PipeTransform } from '@angular/core';
import { ServerService } from '../services/server.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';


declare var $:any;
declare var swal:any;
declare var require: any
declare var jQuery: any
declare var toggle: any
@Component({
  selector: 'app-aio',
  templateUrl: './aio.component.html',
  styleUrls: ['./aio.component.css']
})
@Pipe({
  name: 'safe'
})
@HostListener('window:mouseup', ['$event'])
export class AioComponent implements OnInit {
  
  hostname;
  openNetDet = false;
  showConsole = false;
  showLaunchPad = false;
  aioLanDetails:FormGroup;
  aioSIPDetails:FormGroup;
  aioLantype;
  aioLanmac;
  aioLanaddress;
  aioLannetmask;
  aioLangateway;
  netType;
  consoleURL;
  showLaunchPadUrl;
  mainPortsDetails;
  listSipDetails;
  listSipEndpoitDetails
  listrulesDetails;
  listgroupDetails;
  oldeFileNAme;
  oldCallRoutingname
  showgroupDetails=false;
  showrulesDetails=false;
  showSipEndpoitDetails=false;
  showlistSipDetails = false;
  singleRuleData;
  sVoipEPData;
  addGroupDetails:FormGroup;
  addcallRoutingRuleDetails:FormGroup;
  addcallRoutingRuleDetailsEdit:FormGroup;
  voipForm:FormGroup;
  voipFormEdit:FormGroup;
  constructor(private serverService: ServerService, private router:Router,private route: ActivatedRoute,private sanitizer: DomSanitizer) { 
    this.netType = this.route.snapshot.queryParamMap.get('data');
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.listrulesDetails, event.previousIndex, event.currentIndex);
  }
  ngOnInit() {
    this.hostname = $(location).attr('hostname');
    
    if(localStorage.getItem('access_user') == 'root') {
      
    } else{
      this.router.navigate(['/']);
      return true;
    }
    this.aioLanDetails = new FormGroup({
      mac: new FormControl(null,Validators.required),
      address: new FormControl(null,Validators.required),
      netmask: new FormControl(null,Validators.required),
      def_gateway: new FormControl(null,Validators.required)
    });
    this.addGroupDetails = new FormGroup({
      group_name: new FormControl(null,Validators.required),
      group_type: new FormControl(null,Validators.required),
      group_policy: new FormControl(null,Validators.required)
    });
    this.aioSIPDetails = new FormGroup({
      sipuser: new FormControl(null,Validators.required),
      sipname: new FormControl(null,Validators.required),
      siphost: new FormControl(null,Validators.required),
      sipassword: new FormControl(null,Validators.required),
      sipport: new FormControl(null,Validators.required),
      sipauthUser: new FormControl(null,Validators.required),
      sipregUser: new FormControl(null,Validators.required)
    });
    this.addcallRoutingRuleDetails = new FormGroup({
      route_name: new FormControl(null,Validators.required),
      call_from: new FormControl(null,Validators.required),
      call_send_through: new FormControl(null,Validators.required),
      force_answer: new FormControl('1'),
      secondary_dialing: new FormControl('1'),
      disa_timeout: new FormControl('5'),
      authentication: new FormControl(null,Validators.required),
      max_pass_digit: new FormControl('10',Validators.required),
      password: new FormControl(null,Validators.required),
      forword_number: new FormControl(null,Validators.required),
      dialing_delay: new FormControl('0.00'),
      custom_context: new FormControl(null,Validators.required),
      t_gateway_mode: new FormControl('0'),
      failover_call_number: new FormControl(null,Validators.required)
    });
    this.addcallRoutingRuleDetailsEdit = new FormGroup({
      route_nameEdit:new FormControl(null,Validators.required),
      call_fromEdit:new FormControl(null,Validators.required),
      call_send_throughEdit:new FormControl(null,Validators.required),
      force_answerEdit:new FormControl('1'),
      secondary_dialingEdit:new FormControl('1'),
      disa_timeoutEdit:new FormControl(null,Validators.required),
      authenticationEdit:new FormControl(null,Validators.required),
      max_pass_digitEdit:new FormControl('10',Validators.required),
      passwordEdit:new FormControl(null,Validators.required),
      forword_numberEdit:new FormControl(null,Validators.required),
      dialing_delayEdit: new FormControl('0.00'),
      custom_contextEdit:new FormControl(null,Validators.required),
      t_gateway_modeEdit:new FormControl('0'),
      failover_call_numberEdit:new FormControl(null,Validators.required)
    });
    this.voipForm = new FormGroup({
      name : new FormControl(),
      sip_enable : new FormControl(),
      user_name : new FormControl(),
      password : new FormControl(),
      allowanonymous : new FormControl(),
      registration : new FormControl('0'),
      ipAddress: new FormControl(),
      transPort : new FormControl('0'),
      NATtraversal : new FormControl('2'),
      subscribeMWI: new FormControl('0'),
      stunSwitch: new FormControl(),
      priotiyMatch: new FormControl('1'),
      enable_Outboundproxy: new FormControl('0'),
      authenticateUser : new FormControl(),
      RegExtension : new FormControl(),
      RegUser : new FormControl(),
      formUser : new FormControl(),
       backupHostnameIPAddress:new FormControl(),
      formDomain : new FormControl(),
      remoteSecret : new FormControl(),
      port : new FormControl(),
      qualify : new FormControl('0'),
      qualifyFrequency : new FormControl('60'),
      outBoundproxyport: new FormControl('5060'),
      Prack : new FormControl(),
      telURL : new FormControl(),
      IMSvolte : new FormControl(),
      outBoundproxy : new FormControl(),
      customRegistry : new FormControl(),
      registry_string : new FormControl(),
      callLimit : new FormControl('4'),
      remotePartyITFormat : new FormControl('0'),
      DtmfMode : new FormControl('0'),
      trustRemoteParty : new FormControl('0'),
      sendRemote : new FormControl('0'),
      callerIdPresentation : new FormControl('0'),
      progressInband : new FormControl('0'),
      allowOverlapDialling : new FormControl('0'),
      appendUserUrl : new FormControl('0'),
      addReasonHeader : new FormControl('0'),
      honorSDPversion : new FormControl('1'),
      directMedia : new FormControl(),
      allowTransfer : new FormControl('1'),
      allowPermisRedirect : new FormControl('0'),
      vosEncryption: new FormControl('0'),
      maxForward : new FormControl(70),
      sendTrytoReg : new FormControl('0'),
      defaultTimer : new FormControl(500),
      callSetupTimer : new FormControl(32000),
      sessionTimer : new FormControl('0'),
      HonorSDP : new FormControl(),
      minSessionRef : new FormControl(90),
      maxSessionRef : new FormControl(1800),
      sessionRef : new FormControl('1'),
      protocol : new FormControl(),
      codecPriority1: new FormControl('1'),
      codecPriority2: new FormControl('2'),
      codecPriority3: new FormControl('3'),
      codecPriority4: new FormControl('4'),
      codecPriority5: new FormControl('6'),
      enabled : new FormControl(),
      registertrying: new FormControl('0'),
     // codecPriorit3:new FormControl(),
      'action' : new FormControl("createVoipEndpoints")
  });

  this.voipFormEdit = new FormGroup({
    name : new FormControl(),
    sip_enable : new FormControl(),
    user_name : new FormControl(),
    password : new FormControl(),
    allowanonymous : new FormControl(),
    registration : new FormControl('0'),
    ipAddress: new FormControl(),
    transPort : new FormControl('0'),
    NATtraversal : new FormControl('2'),
    subscribeMWI: new FormControl('0'),
    stunSwitch: new FormControl(),
    priotiyMatch: new FormControl('1'),
    enable_Outboundproxy: new FormControl('0'),
    authenticateUser : new FormControl(),
    RegExtension : new FormControl(),
    RegUser : new FormControl(),
    formUser : new FormControl(),
     backupHostnameIPAddress:new FormControl(),
    formDomain : new FormControl(),
    remoteSecret : new FormControl(),
    port : new FormControl(),
    qualify : new FormControl('0'),
    qualifyFrequency : new FormControl('60'),
    outBoundproxyport: new FormControl('5060'),
    Prack : new FormControl(),
    telURL : new FormControl(),
    IMSvolte : new FormControl(),
    outBoundproxy : new FormControl(),
    customRegistry : new FormControl(),
    registry_string : new FormControl(),
    callLimit : new FormControl('4'),
    remotePartyITFormat : new FormControl('0'),
    DtmfMode : new FormControl('0'),
    trustRemoteParty : new FormControl('0'),
    sendRemote : new FormControl('0'),
    callerIdPresentation : new FormControl('0'),
    progressInband : new FormControl('0'),
    allowOverlapDialling : new FormControl('0'),
    appendUserUrl : new FormControl('0'),
    addReasonHeader : new FormControl('0'),
    honorSDPversion : new FormControl('1'),
    directMedia : new FormControl(),
    allowTransfer : new FormControl('1'),
    allowPermisRedirect : new FormControl('0'),
    vosEncryption: new FormControl('0'),
    maxForward : new FormControl(70),
    sendTrytoReg : new FormControl('0'),
    defaultTimer : new FormControl(500),
    callSetupTimer : new FormControl(32000),
    sessionTimer : new FormControl('0'),
    HonorSDP : new FormControl(),
    minSessionRef : new FormControl(90),
    maxSessionRef : new FormControl(1800),
    sessionRef : new FormControl('1'),
    protocol : new FormControl(),
    codecPriority1: new FormControl('1'),
    codecPriority2: new FormControl('2'),
    codecPriority3: new FormControl('3'),
    codecPriority4: new FormControl('4'),
    codecPriority5: new FormControl('6'),
    enabled : new FormControl(),
   // codecPriorit3:new FormControl(),
    registertrying: new FormControl('0'),
    'action' : new FormControl("createVoipEndpoints")
});
    if(this.netType == 'add_new_sip'){
          this.showLaunchPadList();
    }
   this.showPortsDEtails();
   this.showRoutingGroupGetAll();
  }
showNetworkDetails(){
    // $('.loading').css('display', 'block');
    var req_data = '{"action":"getNetworkLanSettings"}';
    this.serverService.sendAioServer(req_data).subscribe((response:any) => {
      this.openNetDet = true
      this.showConsole=false
      this.showLaunchPad = false
      var convert = require('xml-js');
      var xml =response.data;
      var result1 = convert.xml2json(xml, {compact: true, spaces: 4});
      result1 = JSON.parse(result1);
      const lanSett = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGNetworkGetResponse']['get']['lan'];
      console.log(lanSett);
      this.aioLantype=lanSett['type']['_text'];
      this.aioLanmac=lanSett['mac']['_text'];
      this.aioLanaddress=lanSett['ipaddr']['_text'];
      this.aioLannetmask=lanSett['netmask']['_text'];
      this.aioLangateway=lanSett['gateway']['_text'];

      if(this.aioLanaddress == '' || this.aioLanaddress == undefined){
        this.aioLanaddress = '192.168.101.99';
    }
    if(this.aioLannetmask == '' || this.aioLannetmask == undefined){
        this.aioLannetmask = '255.255.255.0';
    }
    if(this.aioLangateway == '' || this.aioLangateway == undefined){
        this.aioLangateway = '192.168.101.1';
    }
    console.log(this.aioLanaddress);
    },
    (error)=>{
        console.log(error);
    });
}




rebootDevice(){
  $('.loading').css('display', 'block');
  const req_data = '{"action":"rebootSystem"}';
  this.serverService.sendAioServer(req_data).subscribe((response:any) => {
    var convert = require('xml-js');
    var xml =response.data;
    var result1 = convert.xml2json(xml, {compact: true, spaces: 4});
    result1 = JSON.parse(result1);
    const res = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGSystemToolsSystemRebootResponse']['result']['_text'];
    if(res == 0){
      $('.loading').css('display', 'none');
      swal("Success", 'Rebooted Successfully', "success");
    }
  },
  (error)=>{
      console.log(error);
  });
}
hideNetwork(){
  this.openNetDet=false
}
movetoNewDevice(){
  var req_data = '{"action":"getNetworkLanSettings"}';
  this.serverService.sendAioServer(req_data).subscribe((response:any) => {
    var convert = require('xml-js');
    var xml =response.data;
    var result1 = convert.xml2json(xml, {compact: true, spaces: 4});
    result1 = JSON.parse(result1);
    const lanSett = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGNetworkGetResponse']['get']['lan'];
    var url =lanSett['ipaddr']['_text'];
    console.log(url)
    this.consoleURL = "http://"+url+"/aio"
    window.location.href = this.consoleURL;
    console.log(this.consoleURL)
  },
  (error)=>{
      console.log(error);
  });
}






hideConsole(){
  this.showConsole=false
}
showLaunchPadList(){
  $('#openModal-edit').css('display', 'block');
  this.showConsole=false
  this.openNetDet=false
  this.showLaunchPad = false;
}
addLaunchPadList(){
  $('.loading').css('display', 'block');
  var data = this.aioLanDetails.value;
  data['action'] = 'saveLanSettings';
  data = JSON.stringify(data);
  console.log(data);
  this.serverService.sendAioServer(data).subscribe((response:any) => {
    // console.log(response); return false;
    var convert = require('xml-js');
    var xml =response.data;
    var result1 = convert.xml2json(xml, {compact: true, spaces: 4});
    result1 = JSON.parse(result1);
    const res = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGNetworkSaveResponse']['result']['_text'];
    console.log(result1);
    if(res == 0){
      $('.loading').css('display', 'none');
      swal("Success", 'Network Settings Saved Successfully', "success");
      this.showNetworkDetails();
    } else {
      $('.loading').css('display', 'none');
      swal("Error", 'Sorry some error occured', "error");
    }
  },
  (error)=>{
      console.log(error);
  });
}
saveNetworkLanDetails(){
  $('.loading').css('display', 'block');
  var data = this.aioSIPDetails.value;
  data['action'] = 'saveLaunchPadList';
  data = JSON.stringify(data);
  this.serverService.sendAioServer(data).subscribe((response:any) => {
    this.openNetDet = false
    this.showConsole=false
    this.showLaunchPad = false;
    // console.log(response); return false;
    var convert = require('xml-js');
    var xml =response.data;
    var result1 = convert.xml2json(xml, {compact: true, spaces: 4});
    result1 = JSON.parse(result1);
    const res = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGSipEndpointSaveResponse']['result']['_text'];
    console.log(res);
    if(res == 0){
      $('.loading').css('display', 'none');
      $('#openModal-edit').css('display', 'none');
      swal("Success", 'Sip Trunk Added Successfully', "success");
      this.showLaunchPadURL();
      this.aioSIPDetails.reset();
      this.ngOnInit()
    } else {
      $('#openModal-edit').css('display', 'none');
      $('.loading').css('display', 'none');
      this.aioSIPDetails.reset();
      this.ngOnInit()
      swal("Error", 'Sorry some error occured', "error");
    }
  },
  (error)=>{
      console.log(error);
  }); 
}
closeModal(){
  $('#openModal-about').css('display', 'none');
  $('#openModal-abouts').css('display', 'none');
  $('#openModal-failover').css('display', 'none');
  $('#openModal-failoverSwitch').css('display', 'none');
  $('#openModal-edit').css('display', 'none');
  $('#openModal-runFailover').css('display', 'none');
  $('.loading').css('display', 'none');
  this.aioSIPDetails.reset();
  this.ngOnInit()
}

showLaunchPadURL(){
  var req_data = '{"action":"getNetworkLanSettings"}';
  this.serverService.sendAioServer(req_data).subscribe((response:any) => {
    this.showConsole=false
    this.openNetDet=false
    this.showLaunchPad = true;
    var convert = require('xml-js');
    var xml =response.data;
    var result1 = convert.xml2json(xml, {compact: true, spaces: 4});
    result1 = JSON.parse(result1);
    const lanSett = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGNetworkGetResponse']['get']['lan'];
    var url =lanSett['ipaddr']['_text'];
    this.showLaunchPadUrl = "http://"+url+"/Network/VLan"
  },
  (error)=>{
      console.log(error);
  });
}


// demoWebPage(){
//   var iframeURL = 'http://172.16.8.237/';
//     var iframeID = 'MyIFrame';

//   // once you're logged in, change the source url (if needed)
//   var req = new XMLHttpRequest();
//   req.open("POST",iframeURL, false, "admin", "admin"); //use POST to safely send combination
//   req.send(null); //here you can pass extra parameters through

//   //setiFrame's SRC attribute
//   var iFrameWin = document.getElementById(iframeID) as HTMLImageElement;
//   iFrameWin.src = iframeURL;
// }


showPortsDEtails(){
  var req_data = '{"action":"get_analoginfo"}';
  this.serverService.sendAioServer(req_data).subscribe((response:any) => {
    this.mainPortsDetails = response;
  console.log(response);
  this.mainPortsDetails = JSON.parse(JSON.stringify(this.mainPortsDetails ));
  console.log(this.mainPortsDetails);
  },
  (error)=>{
      console.log(error);
  });
 // this.showSystemStatus();
}
showSystemStatus(){
  var req_data = '{"action":"getSystemStatus"}';
  this.serverService.sendAioServer(req_data).subscribe((response:any) => {
    var convert = require('xml-js');
    var xml =response.data;
    var result1 = convert.xml2json(xml, {compact: true, spaces: 4});
    result1 = JSON.parse(result1);
    this.listSipDetails = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGSystemStatusGetResponse']['get']['sip']['item'];
    console.log(this.listSipDetails);
    if(this.listSipDetails == null || this.listSipDetails == undefined){
      return false;    
    }
    if(this.listSipDetails.length){
      this.showlistSipDetails = false
  } else {
      this.showlistSipDetails = true
  }
  },
  (error)=>{
      console.log(error);
  });
  //this.showRoutingRulesGetAll();
}
showRoutingRulesGetAll(){
  var req_data = '{"action":"getRoutingRulesGetAll"}';
  this.serverService.sendAioServer(req_data).subscribe((response:any) => {
    var convert = require('xml-js');
    var xml =response.data;
    var result1 = convert.xml2json(xml, {compact: true, spaces: 4});
    result1 = JSON.parse(result1);
    console.log(result1)
    this.listrulesDetails = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGRoutingRulsGetAllResponse']['get']['ruls']['item'];
    console.log(this.listrulesDetails);
    
    if(this.listrulesDetails == null || this.listrulesDetails == undefined){
      this.listrulesDetails = '';
      return false;    
    } else {
      if(this.listrulesDetails.length > 1){
        this.showrulesDetails = false
      } else {
        this.showrulesDetails = true
      }
    }
   
 // this.showSystemStatus();
  },
  (error)=>{
      console.log(error);
  });
}
showRoutingGroupGetAll(){
  var req_data = '{"action":"getRoutingGroupGetAll"}';
  this.serverService.sendAioServer(req_data).subscribe((response:any) => {
    var convert = require('xml-js');
    var xml =response.data;
    var result1 = convert.xml2json(xml, {compact: true, spaces: 4});
    result1 = JSON.parse(result1);
    this.listgroupDetails = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGRoutingGroupGetAllResponse']['get']['group']['item'];
    
    if(this.listgroupDetails == null || this.listgroupDetails == undefined){
      this.showSystemStatus();
      return false;    
    }
    if(this.listgroupDetails.length){
        this.showgroupDetails = false
    } else {
        this.showgroupDetails = true
    }
    this.showSystemStatus();
  },
  (error)=>{
      console.log(error);
  });
}
saveRoutingGroupSaveList(){

var checkedVals = $('.routingmembersGroup:checkbox:checked').map(function() {
  return this.value;
}).get();
var group_members = checkedVals.join(",");
  // var req_data = '{"action":"saveRoutingGroupSave","group_members":"'+group_members+'","type":"'+group_policy+'","policy":"'+group_type+'","members":"'+group_name+'"}';
  let data = this.addGroupDetails.value;
  data['group_members'] = group_members;
  data['action'] = 'saveRoutingGroupSave';
  data = JSON.stringify(data);
  console.log(data);
  this.serverService.sendAioServer(data).subscribe((response:any) => {
    var convert = require('xml-js');
    var xml =response.data;
    var result1 = convert.xml2json(xml, {compact: true, spaces: 4});
    result1 = JSON.parse(result1);
    const res = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGRoutingGroupSaveResponse']['result']['_text'];
    console.log(res);
    if(res == 0){
      swal("Success", 'Group Added Successfully', "success");
      this.showRoutingGroupGetAll();
      this.addGroupDetails.reset();
      this.ngOnInit()
    } else {
      $('.loading').css('display', 'none');
      this.addGroupDetails.reset();
      this.ngOnInit()
      swal("Error", 'Sorry some error occured', "error");
    }
  },
  (error)=>{
      console.log(error);
  });
}


makeRoutingGroupDel(name){
  $('.loading').css('display', 'block');
  const req_data = '{"action":"getRoutingGroupDel","group_name":"'+name+'"}';
  this.serverService.sendAioServer(req_data).subscribe((response:any) => {
    var convert = require('xml-js');
    var xml =response.data;
    var result1 = convert.xml2json(xml, {compact: true, spaces: 4});
    result1 = JSON.parse(result1);
    const res = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGRoutingGroupsDelResponse']['result']['_text'];
    if(res == '0' || res == 0){
      $('.loading').css('display', 'none');
      swal("Success", 'Group Deleted Successfully', "success");
      this.showRoutingGroupGetAll();
    }
  },
  (error)=>{
      console.log(error);
  });
}
makeRoutingDel(name){
  $('.loading').css('display', 'block');
  const req_data = '{"action":"getRoutingRulesDel","route_name":"'+name+'"}';
  this.serverService.sendAioServer(req_data).subscribe((response:any) => {
    var convert = require('xml-js');
    var xml =response.data;
    var result1 = convert.xml2json(xml, {compact: true, spaces: 4});
    result1 = JSON.parse(result1);
    const res = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGRoutingRulesDelResponse']['result']['_text'];
    if(res == '0' || res == 0){
      $('.loading').css('display', 'none');
      swal("Success", 'Rule Deleted Successfully', "success");
      this.showRoutingRulesGetAll();
    }
  },
  (error)=>{
      console.log(error);
  });
}

showSipEndpoitDetailsGetAll(){
  var req_data = '{"action":"getSipEndpointGetAll"}';
  this.serverService.sendAioServer(req_data).subscribe((response:any) => {
    var convert = require('xml-js');
    var xml =response.data;
    var result1 = convert.xml2json(xml, {compact: true, spaces: 4});
    result1 = JSON.parse(result1);
    console.log(result1);
    this.listSipEndpoitDetails = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGSipEndpointGetAllResponse']['get']['sips']['item'];
    console.log(this.listSipEndpoitDetails);
    // if(this.listSipEndpoitDetails.length){
    //     this.showSipEndpoitDetails = false
    // } else {
    //     this.showSipEndpoitDetails = true
    // }



    if(this.listSipEndpoitDetails == null || this.listSipEndpoitDetails == undefined){
      this.listSipEndpoitDetails = '';
      //alert('ds')
      return false;    
    } else {
      if(this.listSipEndpoitDetails.length > 1){
        this.showSipEndpoitDetails = false
      } else {
        this.showSipEndpoitDetails = true
      }
    }



   //. alert(this.listSipEndpoitDetails.length)
  },
  (error)=>{
      console.log(error);
  });
}
SipEndpoitDetailsDel(name){
  $('.loading').css('display', 'block');
  const req_data = '{"action":"getsipEndpointsDel","sip_name":"'+name+'"}';
  this.serverService.sendAioServer(req_data).subscribe((response:any) => {
    var convert = require('xml-js');
    var xml =response.data;
    var result1 = convert.xml2json(xml, {compact: true, spaces: 4});
    result1 = JSON.parse(result1);
    const res = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGSipEndpointsDelResponse']['result']['_text'];
    if(res == '0' || res == 0){
      $('.loading').css('display', 'none');
      swal("Success", 'Sip Endpoint Deleted Successfully', "success");
      this.showSipEndpoitDetailsGetAll();
    }
  },
  (error)=>{
      console.log(error);
  });
}

addCallRouting(){
    const data = this.addcallRoutingRuleDetails.value;

    var prepend = $("input[name=prepend]").val();  
    var Prefix = $("input[name=Prefix]").val();
    var Match_pat = $("input[name=Match_pat]").val();
    var SDRF = $("input[name=SDRF]").val();
    var Sta = $("input[name=Sta]").val();
    var RdfR = $("input[name=RdfR]").val();
    var prependcd = $("input[name=prependcd]").val();
    var Prefixcd = $("input[name=Prefixcd]").val();
    var Match_patcd = $("input[name=Match_patcd]").val();
    var SDRFcd = $("input[name=SDRFcd]").val();
    var Stacd = $("input[name=Stacd]").val();
    var RdfRcd = $("input[name=RdfRcd]").val();
    var call_name = $("input[name=call_name]").val();
    var call_dPat = $("select[name=call_dPat]").val();
    var timetosta = $("input[name=timetosta]").val();  
    var weekdaystart = $("select[name=weekdaystart]").val();  
    var monthdaystart = $("select[name=monthdaystart]").val();  
    var monthstart = $("select[name=monthstart]").val();  
    var ttfinish = $("input[name=ttfinish]").val();  
    var weekdayfinish = $("select[name=weekdayfinish]").val();  
    var monthdayfinish = $("select[name=monthdayfinish]").val();  
    var monthfinish = $("select[name=monthfinish]").val(); 
    data['action']='saveCallRouteData';

    if(this.listrulesDetails == null || this.listrulesDetails == undefined){
      data['orders']= 0;  
    } else{
      data['orders']=this.listrulesDetails.length;
    }

 
    
    data['calleedialpattern']= prepend+'|'+Prefix+'|'+Match_pat+'|'+SDRF+'|'+Sta+'|'+RdfR;
    data['callerdialpattern']= prependcd+'|'+Prefixcd+'|'+Match_patcd+'|'+SDRFcd+'|'+RdfRcd+'|'+call_name+'|'+call_dPat;
    data['timepattern'] = timetosta+'-'+ttfinish+'|'+weekdaystart+'-'+weekdayfinish+'|'+monthdaystart+'-'+monthdayfinish+'|'+monthstart+'-'+monthfinish;
    const datas = JSON.stringify(data);
    this.serverService.sendAioServer(datas).subscribe((response:any) => {
      var convert = require('xml-js');
      var xml =response.data;
      var result1 = convert.xml2json(xml, {compact: true, spaces: 4});
      result1 = JSON.parse(result1);
      console.log(result1); console.log('123');
      const res = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGRoutingRulsSaveResponse']['result']['_text'];
      if(res == '0' || res == 0){
        $('.loading').css('display', 'none');
        swal("Success", 'Call Routing Successfully', "success");
        this.addcallRoutingRuleDetails.reset();
        this.ngOnInit();
        this.showRoutingRulesGetAll();
      } else{
        $('.loading').css('display', 'none');
        swal("Error", 'Sorry, some error occured', "error");
        this.addcallRoutingRuleDetails.reset();
        this.ngOnInit();
      }
    },
    (error)=>{
        console.log(error);
    });
  }
  addCallRoutingEdit(oldname){
    const data = this.addcallRoutingRuleDetailsEdit.value;
    var prepend = $("input[name=prependEdit]").val();  
    var Prefix = $("input[name=PrefixEdit]").val();
    var Match_pat = $("input[name=Match_patEdit]").val();
    var SDRF = $("input[name=SDRFEdit]").val();
    var Sta = $("input[name=StaEdit]").val();
    var RdfR = $("input[name=RdfREdit]").val();
    var prependcd = $("input[name=prependcdEdit]").val();
    var Prefixcd = $("input[name=PrefixcdEdit]").val();
    var Match_patcd = $("input[name=Match_patcdEdit]").val();
    var SDRFcd = $("input[name=SDRFcdEdit]").val();
    var Stacd = $("input[name=StacdEdit]").val();
    var RdfRcd = $("input[name=RdfRcdEdit]").val();
    var call_name = $("input[name=call_nameEdit]").val();
    var call_dPat = $("select[name=call_dPatEdit]").val();
    var timetosta = $("input[name=timetostaEdit]").val();  
    var weekdaystart = $("select[name=weekdaystartEdit]").val();  
    var monthdaystart = $("select[name=monthdaystartEdit]").val();  
    var monthstart = $("select[name=monthstartEdit]").val();  
    var ttfinish = $("input[name=ttfinishEdit]").val();  
    var weekdayfinish = $("select[name=weekdayfinishEdit]").val();  
    var monthdayfinish = $("select[name=monthdayfinishEdit]").val();  
    var monthfinish = $("select[name=monthfinishEdit]").val(); 


if( data['t_gateway_modeEdit'] =='true' ){
    data['t_gateway_modeEdit'] = 1    
} else if( data['t_gateway_modeEdit'] =='fasle' ){
  data['t_gateway_modeEdit'] = 0    
} else {
  data['t_gateway_modeEdit'] = this.singleRuleData.t38enable    
}

data['secondary_dialingEdit']='1';
    data['action']='saveUpdatedCallRouteData';
    data['old_route_name']=this.oldCallRoutingname;
    data['calleedialpattern']= prepend+'|'+Prefix+'|'+Match_pat+'|'+SDRF+'|'+Sta+'|'+RdfR;
    data['callerdialpattern']= prependcd+'|'+Prefixcd+'|'+Match_patcd+'|'+SDRFcd+'|'+Stacd+'|'+RdfRcd+'|'+call_name+'|'+call_dPat;
    data['timepattern'] = timetosta+'-'+ttfinish+'|'+weekdaystart+'-'+weekdayfinish+'|'+monthdaystart+'-'+monthdayfinish+'|'+monthstart+'-'+monthfinish;
    console.log(data); 
    const datas = JSON.stringify(data);
    this.serverService.sendAioServer(datas).subscribe((response:any) => {
      var convert = require('xml-js');
      var xml =response.data;
      var result1 = convert.xml2json(xml, {compact: true, spaces: 4});
      result1 = JSON.parse(result1);
      console.log(result1); console.log('123');
      const res = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGRoutingRulsSaveResponse']['result']['_text'];
      if(res == '0' || res == 0){
        $('.loading').css('display', 'none');
        swal("Success", 'Call Routing Successfully', "success");
        this.showRoutingRulesGetAll();
        this.closeAllPopups();
      }
    },
    (error)=>{
        console.log(error);
    });
  }
addMoreCalleeManupulation(){
  var input = '<table class="table table-bordered"><tbody><tr>    <td>Callee Dial Pattern:</td>    <td>        <ul class="caller-dial">            <li><input type="text" name="prepend" placeholder="Prepend" class="form-control"></li>            <li><input type="text" name="Prefix" placeholder="Prefix" class="form-control"></li>            <li><input type="text" name="Match_pat" placeholder="Match Pattern" class="form-control"></li>            <li><input type="text" name="SDRF" placeholder="SDRF" class="form-control"></li>            <li><input type="text" name="Sta" placeholder="Sta" class="form-control"></li>            <li><input type="text" name="RdfR" placeholder="RdfR" class="form-control"></li>        </ul>    </td></tr><tr>    <td>Caller Dial Pattern:</td>    <td>        <ul class="caller-dial">            <li><input type="text" name="prependcd" placeholder="Prepend" class="form-control"></li>            <li><input type="text" name="Prefixcd" placeholder="Prefix" class="form-control"></li>            <li><input type="text" name="Match_patcd" placeholder="Match Pattern" class="form-control"></li>            <li><input type="text" name="SDRFcd" placeholder="SDRF" class="form-control"></li>            <li><input type="text" name="Stacd" placeholder="Sta" class="form-control"></li>            <li><input type="text" name="RdfRcd" placeholder="RdfR" class="form-control"></li>            <li><input type="text" name="call_name" placeholder="Caller Name" class="form-control"></li>            <li> <select class="form-select form-control" name="call_dPat" aria-label="Default select example" >                <option selected>Select</option>                <option value="off">Modify_CallerID</option>                <option value="on">Filter_CallerID-1</option>            </select></li>        </ul>    </td></tr></tbody></table>';
  //$('#calleeManipulation').append(input);
}
editRoutingRules(name){
  //alert(name);
  var req_data = '{"action":"getsingleRoutingData","rule_name":"'+name+'"}';
  this.serverService.sendAioServer(req_data).subscribe((response:any) => {
    var convert = require('xml-js');
    var xml =response.data;
    this.oldCallRoutingname = name;
    var result1 = convert.xml2json(xml, {compact: true, spaces: 4});
    result1 = JSON.parse(result1);
    console.log(result1);
    var results = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGRoutingRulsGetOneResponse']['get']['context'];
    console.log(results);
    console.log(name)
    this.singleRuleData = {};
    this.singleRuleData['name'] = name;
    this.singleRuleData['fromchannel'] = results.fromchannel._text;
    this.singleRuleData['tochannel'] = results.tochannel._text.split(',');
    this.singleRuleData['calleedialpattern'] = results.calleedialpattern._text.split('|');
    this.singleRuleData['callerdialpattern'] = results.callerdialpattern._text.split('|');
    this.singleRuleData['dialingdelay'] = results.dialingdelay._text;
    this.singleRuleData['disasw'] = results.disasw._text;
    this.singleRuleData['forceanswer'] = results.forceanswer._text;
    this.singleRuleData['forwardnumber'] = results.forwardnumber._text;
    this.singleRuleData['maxpasswddigits'] = results.maxpasswddigits._text;
    this.singleRuleData['t38enable'] = results.t38enable._text;
    this.singleRuleData['timeout'] = results.timeout._text;
    this.singleRuleData['timepattern'] = {}
    var timePat = results.timepattern._text.split('|');
    this.singleRuleData['timepattern'][0] = timePat[0].split('-');
    this.singleRuleData['timepattern'][1] = timePat[1].split('-');
    this.singleRuleData['timepattern'][2] = timePat[2].split('-');
    this.singleRuleData['timepattern'][3] = timePat[3].split('-');
    this.singleRuleData['password'] = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGRoutingRulsGetOneResponse']['get']['revcsecret']['_text'];
    $('#routeraddmodalEdit').show();
    console.log(this.singleRuleData);
    
   
  },
  (error)=>{
      console.log(error);
  });
}
closeAllPopups(){
  $('#routeraddmodalEdit').hide(100); 
}

isNumeric(value) {
  return /^-?\d+$/.test(value);
}
isIpAddress(s) {
  if (typeof s !== 'string') { return false; }
  // There must be 4 parts separated by dots.
  var parts = s.split('.');
  if (parts.length !== 4) { return false; }
  // Each of the four parts must be an integer in the range 0 to 255.
  for (var i = 0; i < 4; ++i) {
    var part = parts[i];
    // Each part must consist of 1 to 3 decimal digits.
    if (!/^\d{1,3}$/.test(part)) { return false; }
    var n = +part;
    if (0 > n || n > 0xff) { return false; }
  }
  return true;
}
appliedVoipData(){  
    $('.loading').css('display', 'block');
    var data = this.voipForm.value;


    if(data['user_name'] == '' || data['user_name'] == null){
      swal("Error", 'Please Enter the Username', "error");
      return false
    }
    if(data['password'] == '' || data['password'] == null){
      swal("Error", 'Please Enter the Password', "error");
      return false
    }

    if(data['ipAddress'] == '' || data['ipAddress'] == null){
      swal("Error", 'Please Enter the IP Address', "error");
      return false
    } else {
      var dat = this.isIpAddress(data['ipAddress'])
        if(dat == false){
          swal("Error", 'Please Enter the valid ip address', "error");
          return false
        }
    }
    if(data['port'] == '' || data['port'] == null){
      swal("Error", 'Please Enter the Port', "error");
      return false
    } else {
        var dat = this.isNumeric(data['port'])
        if(dat == false){
          swal("Error", 'Please Enter the valid port', "error");
          return false
        }
    }
//return false;
    if(data['allowanonymous'] == '' || data['allowanonymous'] == null || data['allowanonymous'] == false || data['allowanonymous'] == 'false'){
      data['allowanonymous'] = 0
    } else {
      data['allowanonymous'] = 1
    }
    if(data['RegUser'] == '' || data['RegUser'] == null){
      data['RegUser'] =  data['user_name'];
    }
    if(data['customRegistry'] == '' || data['customRegistry'] == null  || data['customRegistry'] == false){
      data['customRegistry'] = '0';
    } else {
      data['customRegistry'] = '1';
    }

    data['action'] = "saveSpiEndpointsData";
    data = JSON.stringify(data);
    this.serverService.sendAioServer(data).subscribe((response:any) => {
      this.openNetDet = false
      this.showConsole=false
      this.showLaunchPad = false;
      if(response.code !== 200){
        $('#endpointaddmodal').css('display', 'none');
        $('.loading').css('display', 'none');
        this.voipForm.reset();
        this.ngOnInit()
        swal("Error", 'Sorry some error occureds', "error");
        return false;
      } else {
        // console.log(response); return false;
        var convert = require('xml-js');
        var xml =response.data;
        var result1 = convert.xml2json(xml, {compact: true, spaces: 4});
        result1 = JSON.parse(result1);
        const res = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGSipEndpointSaveResponse']['result']['_text'];
        console.log(res);
        if(res == 0){
          $('.loading').css('display', 'none');
          $('#popup2').click();
          this.showSipEndpoitDetailsGetAll();
          swal("Success", 'Sip Trunk Added Successfully', "success");
          this.voipForm.reset();
          this.ngOnInit()
          
        } else {
          $('#endpointaddmodal').css('display', 'none');
          $('.loading').css('display', 'none');
          this.voipForm.reset();
          this.ngOnInit()
          swal("Error", 'Sorry some error occured', "error");
        }
      }
    },
    (error)=>{
        console.log(error);
    }); 
}


editSipEP(name){
  this.oldeFileNAme = name;
  var req_data = '{"action":"getsingleSipEndpoitData","ep_name":"'+name+'"}';
  this.serverService.sendAioServer(req_data).subscribe((response:any) => {
    var convert = require('xml-js');
    var xml =response.data;
    var result1 = convert.xml2json(xml, {compact: true, spaces: 4});
    result1 = JSON.parse(result1);
    console.log(result1);
    var results = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGSipEndpointGetOneResponse']['get']['context'];
    console.log(results); 

  $('#endpointaddmodalEdit').css('overflow', 'scroll');
    //return false;
    this.sVoipEPData = {};
    this.sVoipEPData['name'] = name;
    this.sVoipEPData['username'] = results.username._text;
    this.sVoipEPData['usereqphone'] = results.usereqphone._text;
    this.sVoipEPData['vosencrypt'] = results.vosencrypt._text;
    this.sVoipEPData['useq850reason'] = results.useq850reason._text;
    this.sVoipEPData['allow'] = {}
    var timePat = results.allow._text.split("");
    this.sVoipEPData['allow'] = timePat;

    this.sVoipEPData['allowanonymous'] = results.allowanonymous._text;
    this.sVoipEPData['allowoverlap'] = results.allowoverlap._text;
    this.sVoipEPData['allowtransfer'] = results.allowtransfer._text;
    this.sVoipEPData['auth'] = results.auth._text;
    this.sVoipEPData['backup'] = results.backup._text;
    this.sVoipEPData['callingpres'] = results.callingpres._text;
    this.sVoipEPData['calllimit'] = results.calllimit._text;
    this.sVoipEPData['dtmfmode'] = results.dtmfmode._text;
    this.sVoipEPData['enableoutboundtohost'] = results.enableoutboundtohost._text;
    this.sVoipEPData['encryption'] = results.encryption._text;
    this.sVoipEPData['fromdomain'] = results.fromdomain._text;
    this.sVoipEPData['fromuser'] = results.fromuser._text;
    this.sVoipEPData['highpriority'] = results.highpriority._text;
    this.sVoipEPData['honorsdpversion'] = results.honorsdpversion._text;
    this.sVoipEPData['host'] = results.host._text;
    this.sVoipEPData['maxforwards'] = results.maxforwards._text;
    this.sVoipEPData['md5'] = results.md5._text;
    this.sVoipEPData['mwi'] = results.mwi._text;
    this.sVoipEPData['nat'] = results.nat._text;
    this.sVoipEPData['order'] = results.order._text;
    this.sVoipEPData['outboundproxy'] = results.outboundproxy._text;
    this.sVoipEPData['outboundproxyport'] = results.outboundproxyport._text;
    this.sVoipEPData['port'] = results.port._text;
    this.sVoipEPData['progressinband'] = results.progressinband._text;
    this.sVoipEPData['promiscredir'] = results.promiscredir._text;
    this.sVoipEPData['qualify'] = results.qualify._text;
    this.sVoipEPData['qualifyfreq'] = results.qualifyfreq._text;
    this.sVoipEPData['registerextension'] = results.registerextension._text;
    this.sVoipEPData['registertrying'] = results.registertrying._text;
    this.sVoipEPData['registeruser'] = results.registeruser._text;
    this.sVoipEPData['registeryenable'] = results.registeryenable._text;
    this.sVoipEPData['registerystring'] = results.registerystring._text;
    this.sVoipEPData['registration'] = results.registration._text;
    this.sVoipEPData['rpidformat'] = results.rpidformat._text;
    this.sVoipEPData['secret'] = results.secret._text;
    this.sVoipEPData['sendrpid'] = results.sendrpid._text;
    this.sVoipEPData['sessionexpires'] = results.sessionexpires._text;
    this.sVoipEPData['sessionminse'] = results.sessionminse._text;
    this.sVoipEPData['sessionrefresher'] = results.sessionrefresher._text;
    this.sVoipEPData['sessiontimers'] = results.sessiontimers._text;
    this.sVoipEPData['sipenable'] = results.sipenable._text;
    this.sVoipEPData['stunflag'] = results.stunflag._text;
    this.sVoipEPData['timerb'] = results.timerb._text;
    this.sVoipEPData['timert1'] = results.timert1._text;
    this.sVoipEPData['tlsenable'] = results.tlsenable._text;
    this.sVoipEPData['tlsprivatekey'] = results.tlsprivatekey._text;
    this.sVoipEPData['tlssetup'] = results.tlssetup._text;
    this.sVoipEPData['tlsverify'] = results.tlsverify._text;
    this.sVoipEPData['transport'] = results.transport._text;
    this.sVoipEPData['trustrpid'] = results.trustrpid._text;
    console.log(this.sVoipEPData)
    jQuery.noConflict();
    $('#endpointaddmodalEdit').modal('show');
  },
  (error)=>{
      console.log(error);
  });
}


updateVoipData(oldname){  
  $('.loading').css('display', 'block');
  var data = this.voipFormEdit.value;

 
  if(data['user_name'] == '' || data['user_name'] == null){
    swal("Error", 'Please Enter the Username', "error");
    return false
  }
  if(data['password'] == '' || data['password'] == null){
    swal("Error", 'Please Enter the Password', "error");
    return false
  }

  if(data['ipAddress'] == '' || data['ipAddress'] == null){
    swal("Error", 'Please Enter the IP Address', "error");
    return false
  } else {
    var dat = this.isIpAddress(data['ipAddress'])
      if(dat == false){
        swal("Error", 'Please Enter the valid ip address', "error");
        return false
      }
  }
  if(data['port'] == '' || data['port'] == null){
    swal("Error", 'Please Enter the Port', "error");
    return false
  } else {
      var dat = this.isNumeric(data['port'])
      if(dat == false){
        swal("Error", 'Please Enter the valid port', "error");
        return false
      }
  }
//return false;
  if(data['allowanonymous'] == '' || data['allowanonymous'] == null || data['allowanonymous'] == false || data['allowanonymous'] == 'false'){
    data['allowanonymous'] = 0
  } else {
    data['allowanonymous'] = 1
  }
  if(data['RegUser'] == '' || data['RegUser'] == null){
    data['RegUser'] =  data['user_name'];
  }
  if(data['customRegistry'] == '' || data['customRegistry'] == null  || data['customRegistry'] == false){
    data['customRegistry'] = '0';
  } else {
    data['customRegistry'] = '1';
  }
  
  data['oldname'] = this.oldeFileNAme;
  data['action'] = "updateSpiEndpointsData";
  data = JSON.stringify(data);
  this.serverService.sendAioServer(data).subscribe((response:any) => {
    this.openNetDet = false
    this.showConsole=false
    this.showLaunchPad = false;
    // console.log(response); return false;
    var convert = require('xml-js');
    var xml =response.data;
    var result1 = convert.xml2json(xml, {compact: true, spaces: 4});
    result1 = JSON.parse(result1);
    const res = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGSipEndpointSaveResponse']['result']['_text'];
    console.log(res);
    if(res == 0){
      $('.loading').css('display', 'none');
      $('#popup3').click();
      this.showSipEndpoitDetailsGetAll();
      swal("Success", 'Sip Trunk Updated Successfully', "success");
      this.voipForm.reset();
      //this.ngOnInit()
      
    } else {
      $('#endpointaddmodal').css('display', 'none');
      $('.loading').css('display', 'none');
      this.voipForm.reset();
      this.ngOnInit()
      swal("Error", 'Sorry some error occured', "error");
    }
  },
  (error)=>{
      console.log(error);
  }); 
}

togglerequire(id){
 // $('#'+id).attr('disabled').toggle();
  var isDisabled = $('#'+id).prop('disabled');
  if(isDisabled == true){
    $('#'+id).removeAttr("disabled");
  } else {
    $('#'+id).attr("disabled", true);
  }
 
}

CallRoutingOrders(){  
  //$('.loading').css('display', 'block');


var valueArray = $('.saveOrders').map(function() {
  return this.value;
}).get();

valueArray = JSON.stringify(valueArray);
  var req_data = '{"action":"saveCallRoutingOrders","data":'+valueArray+'}';
  console.log(req_data);
  this.serverService.sendAioServer(req_data).subscribe((response:any) => {

    // console.log(response); return false;
    var convert = require('xml-js');
    var xml =response.data;
    var result1 = convert.xml2json(xml, {compact: true, spaces: 4});
    result1 = JSON.parse(result1);
    const res = result1['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ast:AGRoutingRulesSaveOrderResponse']['result']['_text'];
    console.log(res);
    if(res == 0){
      $('.loading').css('display', 'none');
      swal("Success", 'ReOrder done Successfully', "success");
      this.showRoutingGroupGetAll()
      
    } else {
      $('.loading').css('display', 'none');
      swal("Error", 'Sorry some error occured', "error");
    }
  },
  (error)=>{
      console.log(error);
  }); 
}


}
